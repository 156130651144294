import { LoaderOverlay, LoaderContainer } from './Loader.styles.jsx';
const Loader = ({ isComponent }) => {
  return (
    <LoaderOverlay compo={isComponent}>
      <LoaderContainer compo={isComponent} />
    </LoaderOverlay>
  )
}

export default Loader
