import axios from 'axios';
import Nprogress from 'nprogress';
import 'nprogress/nprogress.css';
import { BASE_URL, _URL } from './constants';

const apiClient = axios.create({
  baseURL: BASE_URL,
  headers: {
    Accept: 'application/json',
  },
});

const api2Client = axios.create({
  baseURL: _URL,
  headers: {
    Accept: 'application/json',
  },
});
apiClient.interceptors.request.use(function (config) {
  const user = JSON.parse(localStorage.getItem('nasims-test-admin'));
  config.headers.Authorization = user ? `B ${user.token}` : '';

  return config;
});
api2Client.interceptors.request.use(function (config) {
  const user = JSON.parse(localStorage.getItem('nasims-test-admin'));
  config.headers.Authorization = user ? `B ${user.token}` : '';

  return config;
});

apiClient.interceptors.request.use((config) => {
  Nprogress.start();
  return config;
});

api2Client.interceptors.request.use((config) => {
  Nprogress.start();
  return config;
});
apiClient.interceptors.response.use(
  (response) => {
    Nprogress.done();
    return response;
  },
  (error) => {
    Nprogress.done();
    if (error.response && error.response.status === 403) {
      localStorage.removeItem('nasims-test-admin');
      window.location.reload();
    }
    return Promise.reject(error);
  },
);

api2Client.interceptors.response.use(
  (response) => {
    Nprogress.done();
    return response;
  },
  (error) => {
    Nprogress.done();
    if (error.response && error.response.status === 403) {
      localStorage.removeItem('nasims-test-admin');
      window.location.reload();
    }
    return Promise.reject(error);
  },
);
export { apiClient, api2Client };
