import React, {useContext, useEffect, useState} from "react";
import {emitToast} from "../../Register/Register";
import {useHistory} from "react-router-dom";
import AuthContext from "../../context/authContext";
import axios from "axios";
import {apiClient} from "../../service";
import {ContainerStyle} from "./style";
import CustomInput from "../CustomInput/CustomInput";
import CustomButton from "../CustomButton/CustomButton";

const ClearBVN = ({applicant, handleClose}) => {
    const [bvn, setBvn] = useState('');
    const history = useHistory()
    const { getCurrentUser } = useContext(AuthContext)
    const user = getCurrentUser()
    let source = axios.CancelToken.source()
    let config = { cancelToken: source.token}
    const [formErrors, setFormErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)


    const validate = () => {
        let errors = {}
        if (bvn.trim() === '') {
            errors.bvn = 'BVN field required'
        }
        if (Object.keys(errors).length === 0) {
            return true
        }
        setFormErrors(errors)
        return false
    }


    if (!user) {
        history.push('/login')
    }

    const handleInputChange = ({ target }) => {
       setBvn(target.value)
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        setFormErrors({})

        if(validate()) {
            const data = new FormData()
            data.append('bvn', bvn.trim())

            try {
                const response = await apiClient.delete(
                    '/applicants/delete/bvn',
                    {...config, data}
                )
                setIsLoading(false)
                if (response.status === 200) {
                    emitToast('success', 'BVN Successfully cleared.')
                    handleClose()
                }
            }catch (error) {
                setIsLoading(false)
                emitToast('danger', 'Unable to clear BVN.')
                setFormErrors({error: error.response?.data?.message})
                console.log(error)
            }
        }else {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        return () => {
            source.cancel("Unmounted")
        }
    }, []);


    return (
        <ContainerStyle>
            <h2 className='title'>Clear BVN details</h2>
            {Object.keys(formErrors).length > 0 &&
            Object.keys(formErrors).map((err, id) => (
                <p style={{ color: 'red' }} key={id}>
                    {formErrors[err]}
                </p>
            ))}
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="input__group">
                        <label htmlFor="bvn">BVN</label>
                        <CustomInput
                            type='number'
                            height='45px'
                            color='#fff'
                            radius='5px'
                            nomargin
                            handleChange={handleInputChange}
                            name='bvn'
                            id="bvn"
                            value={bvn}
                        />
                    </div>
                </div>
                <div className="button__container">
                    <CustomButton
                        text='Submit'
                        noshadow
                        border='3px'
                        width='360px'
                        nomargin
                        style={{ marginBottom: '0px' }}
                        type='submit'
                        isLoading={isLoading}
                    />
                </div>
            </form>
        </ContainerStyle>
    )
}

export default ClearBVN
