import React from "react";
import {SuccessContainerStyle} from "./style";
import CustomButton from "../CustomButton/CustomButton";
import successIcon from "../../images/success-logo.svg"

const Successful = ({handleClose, isBiometrics}) => {
  return (
      <SuccessContainerStyle>
          <img src={successIcon} alt=""/>
          {isBiometrics? (
              <p className="green__text">Biometric record have been cleared successfully</p>
          ) : (
              <p className="green__text">Details have been changed successfully</p>
          )}
          <p>Changes will be reflected on the user’s portal when next they log in.</p>
          <CustomButton
              text='Close'
              noshadow
              border='3px'
              width='100%'
              nomargin
              style={{ marginBottom: '0px' }}
              handleButtonClick={handleClose}
          />
      </SuccessContainerStyle>
  )
}

export default Successful