import React, { useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { Style } from './modal.style'

const Modal = ({ show, onClose, children, width }) => {
  const [isBrowser, setIsBrowser] = useState(false)
  const modalWrapperRef = useRef()

  // const backDropHandler = e => {
  //   if (!modalWrapperRef?.current?.contains(e.target)) {
  //     onClose()
  //   }
  // }

  const modalContent = show ? (
    <Style.ModalOverlay>
      <Style.ModalWrapper ref={modalWrapperRef} width={width}>
        <Style.Modal>
          <Style.ModalClose onClick={() => onClose()}></Style.ModalClose>
          <Style.ModalContent>{children}</Style.ModalContent>
        </Style.Modal>
      </Style.ModalWrapper>
    </Style.ModalOverlay>
  ) : null

  useEffect(() => {
    setIsBrowser(true)

    // window.addEventListener('click', backDropHandler)

    // return () => window.removeEventListener('click', backDropHandler)
  }, [])

  if (isBrowser) {
    return ReactDOM.createPortal(
      modalContent,
      document.getElementById('modal-root')
    )
  } else {
    return null
  }
}

export default Modal
