import styled from 'styled-components'

export const Style = {
  Container: styled.div`
    background-color: ${props => props.theme.white};
    width: 100%;
  `,
  Header: styled.div`
    padding-right: 75.5px;
    padding-left: 97px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border: 1px solid #eaedf3;
    box-sizing: border-box;
    position: relative;

    .logout {
      position: absolute;
      top: 80%;
      right: 42px;
      width: 219px;
      height: 56px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #ffffff;
      border: 1px solid #eaedf3;
      box-sizing: border-box;
      p {
        font-family: ApercuPro;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #4f4f4f;
        margin: 0;
      }
    }

    .logo-date-group {
      display: flex;
      align-items: center;
      .logo-group {
        margin-right: 39px;
        display: flex;
        align-items: center;

        .logo {
          background: url(${({ logo }) => logo});
          background-size: contain;
          background-repeat: no-repeat;
          background-position: center;
          height: 45px;
          width: 48px;
          margin-right: 8px;
        }

        h1.text {
          font-family: ${props => props.theme.secondaryFont};
          font-style: normal;
          font-weight: 600;
          font-size: 18px;
          line-height: 27px;
          color: #444444;
        }
      }

      p {
        font-family: ${props => props.theme.primaryFont};
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: #828282;
      }
    }

    .avater-group {
      border-left: 1px solid #eaedf3;
      padding-left: 30px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      cursor: pointer;
      .name {
        margin-right: 10px;
        margin-left: 16px;
        font-family: ${props => props.theme.secondaryFont};

        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        color: #4f4f4f;
      }
    }
  `,
  Content: styled.div`
    min-height: 90vh;
  `
}
