import React, { useEffect } from 'react'

import { usePagination, useTable } from 'react-table'
import Pagination from '../Pagination/Pagination'
import { TableContainer, TableHeader, TableRow } from './Table.style'

const NextArrow = () => {
  return (
    <svg
      width='9'
      height='14'
      viewBox='0 0 9 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M1 1L7 7L1 13' stroke='#777474' stroke-width='2' />
    </svg>
  )
}
const PrevArrow = () => {
  return (
    <svg
      width='9'
      height='14'
      viewBox='0 0 9 14'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      transform='rotate(180)'
    >
      <path d='M1 1L7 7L1 13' stroke='#777474' stroke-width='2' />
    </svg>
  )
}

const Table = ({
  columns,
  data,
  fetchPaginatedData,
  pageCount: controlledPageCount,
  initialState,
  rowHandler
}) => {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    prepareRow,
    state: { pageIndex },

    pageOptions,
    gotoPage
  } = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: initialState },
      manualPagination: true,
      pageCount: controlledPageCount
    },
    usePagination
  )
  useEffect(() => {
    if (fetchPaginatedData) {
      fetchPaginatedData(pageIndex)
    }
    // eslint-disable-next-line
  }, [pageIndex])

  return (
    <>
      <div>
        <TableContainer {...getTableProps()}>
          <thead>
            {headerGroups.map(headerGroup => (
              <TableHeader {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map(column => (
                  <th {...column.getHeaderProps()}>
                    {column.render('Header')}
                  </th>
                ))}
              </TableHeader>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map(row => {
              prepareRow(row)
              return (
                <TableRow
                  {...row.getRowProps()}
                  onClick={() => rowHandler(row.original)}
                  className='record'
                >
                  {row.cells.map(cell => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render('Cell')}</td>
                    )
                  })}
                </TableRow>
              )
            })}
            {page.length === 0 ? (
              <TableRow>
                <td align='center' colSpan={columns.length}>
                  No record found
                </td>
              </TableRow>
            ) : null}
          </tbody>
        </TableContainer>
      </div>
      {page.length > 0 ? (
        <Pagination
          onPageChange={gotoPage}
          pages={pageOptions.length}
          page={initialState}
          nextText={<NextArrow />}
          previousText={<PrevArrow />}
        />
      ) : null}
    </>
  )
}

export default Table
