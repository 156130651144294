import styled from "styled-components";

export const Style = {
    Modal__Container: styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    h1 {
      font-weight: bold;
      font-size: 25px;
      line-height: 32px;
      color: #333333;
      @media (max-width: 1080px) {
        font-size: 20px;
        text-align: center;
      }
    }
    p {
      font-size: 16px;
      line-height: 24px;
      color: red;
    }
    .modal-title {
      margin-bottom: 50px;
    }
    .drag-area-container {
      width: 100%;
      cursor: pointer;
      text-align: center;
    }
    .drag-area {
      background: rgba(237, 242, 255, 0.2);
      border: 2px dashed rgba(1, 174, 239, 0.6);
      border-radius: 6px;
      display: flex;
      align-items: center;
      @media (max-width: 1080px) {
        flex-direction: column;
        justify-content: center;
      }
      .underline {
        text-decoration: underline;
      }
      .upload-info{
        width: 100%;
      }
      .info-title {
        font-size: 18px;
        line-height: 27px;
        @media (max-width: 1080px) {
          font-size: 16px;
          text-align: center;
        }
      }
      .mb-5 {
        margin-bottom: 5px;
      }
      .upload-icon {
        background: rgba(1, 174, 239, 0.1);
        border-radius: 15.7759px;
        margin-right: 23px;
        width: 70.55px;
        height: 70.55px;
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width: 1080px) {
          margin-right: 0px;
          margin-bottom: 16px;
        }
      }
      p {
        color: var(--dark);
        @media (max-width: 1080px) {
          font-size: 14px;
        }
      }
    }
  `,
    Drop__Container: styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    .progress {
      width: 95%;
    }
    .file-container {
      border: 2px dashed rgba(1, 174, 239, 0.6);
      border-radius: 6px;
      justify-content: center;
      display: flex;
      align-items: center;
      width: 100%;
      margin-bottom: 31px;
      @media (max-width: 1080px) {
        flex-direction: column;
      }
      .drop-icon {
        margin-right: 19px;
        width: 64px;
        height: 64px;
        @media (max-width: 1080px) {
          margin-right: 0px;
          margin-bottom: 19px;
        }
      }
      .drop-info {
        h1.file-name {
          font-size: 18px;
          color: var(--darker);
          @media (max-width: 1080px) {
            font-size: 16px;
            text-align: center;
          }
        }
        @media (max-width: 1080px) {
          width: 100%;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
        }
        .info {
          display: flex;
          gap: 10px;
          align-items: center;
          p {
            margin-top: 0;
            font-size: 16px;
            color: var(--dark);
            @media (max-width: 1080px) {
              font-size: 14px;
            }
          }
        }
      }
    }
  `,
};
