import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import AuthContext from '../../context/authContext'
import { apiClient } from '../../service'
import CustomButton from '../CustomButton/CustomButton'
import CustomInput from '../CustomInput/CustomInput'
import { Style } from './createPassword.style'

const CreatePassword = ({ handleClose, applicant, refetch }) => {
  const [state, setState] = useState({
    password: '',
    confirmPassword: '',
    created: false
  })
  const history = useHistory()
  const { getCurrentUser } = useContext(AuthContext)
  const user = getCurrentUser()
  const [formErrors, setFormErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  if (!user) {
    history.push('/login')
  }

  const validate = () => {
    let errors = {}
    if (state.password.trim() === '') {
      errors.password = 'Password cannot be empty'
    } else if (state.password.length < 4) {
      errors.password = 'Password must be more than 4 characters'
    } else if (state.password !== state.confirmPassword) {
      errors.password = 'Password mismatch'
    }
    if (Object.keys(errors).length === 0) {
      return true
    }
    setFormErrors(errors)
    return false
  }

  const handleCreate = async e => {
    e.preventDefault()
    console.log(state)
    setIsLoading(true)
    setFormErrors({})

    if (validate()) {
      const form = new FormData()
      form.append('name', applicant.name)
      form.append('ccs_agent_id', user.data.name)
      form.append('password', state.password)
      form.append('confirm_password', state.confirmPassword)
      try {
        const response = await apiClient.post(
          '/applicants/password/create',
          form
        )
        setIsLoading(false)
        console.log(response)
        if (response.status === 200) {
          setState(prevState => ({ ...prevState, created: true }))
          refetch()
        }
      } catch (error) {
        setIsLoading(false)
        setFormErrors({erorr: error.response?.data.message})
        console.log(error)
      }
    } else {
      setIsLoading(false)
    }
  }

  const handleInputChange = ({ target }) => {
    setState(prevState => ({ ...prevState, [target.name]: target.value }))
  }
  return (
    <Style.Container>
      {state.created ? (
        <>
          <div className='success-group'>
            <div className='success-logo'></div>
            <h2>Password Creation Successful</h2>
            <p>
              You have successfully created a new password for this applicant.
            </p>
            <CustomButton
              text='Close'
              noshadow
              border='3px'
              width='100%'
              nomargin
              style={{ marginBottom: '0px' }}
              handleButtonClick={handleClose}
            />
          </div>
        </>
      ) : (
        <form onSubmit={handleCreate}>
          <h2 className='title'>Create Password</h2>
          <div className='input-group mb'>
            {Object.keys(formErrors).length > 0 &&
              Object.keys(formErrors).map((err, id) => (
                <p style={{ color: 'red' }} key={id}>
                  {formErrors[err]}
                </p>
              ))}
            <p className='label'>Password</p>
            <CustomInput
              placeholder={'Enter Password'}
              type='password'
              height='50px'
              color='#fff'
              radius='5px'
              nomargin
              width='100%'
              handleChange={handleInputChange}
              name='password'
            />
          </div>
          <div className='input-group mbut'>
            <p className='label'>Confirm Password</p>
            <CustomInput
              placeholder={'Enter Password'}
              type='password'
              height='50px'
              color='#fff'
              radius='5px'
              nomargin
              width='100%'
              handleChange={handleInputChange}
              name='confirmPassword'
            />
          </div>
          <CustomButton
            text='Create'
            noshadow
            border='3px'
            width='100%'
            nomargin
            style={{ marginBottom: '0px' }}
            type='submit'
            isLoading={isLoading}
          />
        </form>
      )}
    </Style.Container>
  )
}

export default CreatePassword
