import styled from 'styled-components'

export const DropDownContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`
export const DropDownOptionGroup = styled.ul`
  position: absolute;
  top: 110%;
  left: 0px;
  padding: 0;
  margin: 0;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  box-sizing: border-box;
  width: 100%;
  color: #3faffa;
  max-height: 210px;
  overflow-y: scroll;
  z-index: 50;
  &:first-child {
    padding-top: 0.8em;
  }
  @media only screen and (max-width: 800px) {
    z-index: 15;
  }
`
export const DropDownOption = styled.li`
  list-style: none;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  padding: 0px;
  padding-left: 14px;

  color: #777474;
  margin: 0px;

  &:hover {
    cursor: pointer;
    background: ${props => props.theme.inputFieldColor};
  }
`
export const DropDownSelect = styled.div`
  background: #ffffff;
  border: 1px solid #e0dede;
  box-sizing: border-box;
  border-radius: ${({ radius }) => (radius ? radius : '2px')};
  height: ${({ height }) => (height ? height : '40px')};
  display: flex;
  position: relative;
  align-items: center;
  justify-content: start;
  padding-left: 14px;
  cursor: pointer;
  width: ${({ width }) => (width ? width : '100%')};
`

export const DropDownSelectOption = styled.h2`
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 28px;
  padding: 0px;
  width: 100%;

  color: #777474;
  margin: 0px;
`

export const DropDownArrow = styled.img`
  position: absolute;
  top: 17px;
  right: 16px;
`

export const DropDownLabel = styled.h2`
  font-family: ApercuPro;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;

  color: #767676;
`
