import Login from './Login/Login'
import Applicants from './Applicants/Applicants'
import Details from './Applicants/Details'
import { ROUTES } from './constants.js'
import Register from './Register/Register'

const { HOME, LOGIN, APPLICANTS, APPLICANTS_DETAILS, REGISTER } = ROUTES

export const routes = [
  { path: LOGIN, component: Login, exact: true },
  { path: REGISTER, component: Register, exact: true },
  { path: APPLICANTS, component: Applicants, exact: true },
  { path: APPLICANTS_DETAILS, component: Details, exact: true },
  { path: HOME, exact: true }
]
