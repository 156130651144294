import styled from 'styled-components'
import Icon from '../../images/success-logo.svg'

export const Style = {
  Container: styled.div`
    flex-basis: 100%;
    padding: 0px 46px 68px 46px;

    h2.title {
      font-family: ApercuPro;
      font-style: normal;
      font-weight: bold;
      font-size: 22px;
      line-height: 30px;
      text-align: center;
      color: #333333;
      margin: 0;
      margin-bottom: 45px;
    }

    .input-group {
      width: 100%;
    }

    .mbut {
      margin-bottom: 52px;
    }
    .mb {
      margin-bottom: 28px;
    }

    p.label {
      font-family: ApercuPro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      color: #444444;
      margin: 0;
    }

    .success-group {
      display: flex;
      flex-direction: column;
      align-items: center;

      .success-logo {
        width: 70px;
        height: 70px;
        background: url(${Icon});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
      }

      h2 {
        margin: 0;
        font-family: ApercuPro;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 24px;
        text-align: center;
        color: #288855;
        margin-top: 28px;
        margin-bottom: 28px;
      }

      p {
        font-family: Rubik;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #485465;
        margin: 0;
        margin-bottom: 70px;
      }
    }
  `
}
