import React, { useState, useEffect, useContext } from 'react';
import {
  LoginContainer,
  LoginContentContainer,
  LoginHeaderText,
  LoginText,
  DangerText,
} from './Login.style';
import Logo from '../images/logo.png';
import { Link, useHistory } from 'react-router-dom';
import CustomButton from '../components/CustomButton/CustomButton';
import CustomInput from '../components/CustomInput/CustomInput';
import AuthContext from '../context/authContext';
import { ROUTES } from '../constants';
import MetaTags from 'react-meta-tags';

const Login = () => {
  const { APPLICANTS } = ROUTES;
  const history = useHistory();
  const { login, getCurrentUser } = useContext(AuthContext);
  useEffect(() => {
    const user = getCurrentUser();
    if (user) {
      history.push(APPLICANTS);
    }
    // eslint-disable-next-line
  }, [history]);

  const [credentials, setCredentials] = useState({
    email_address: null,
    password: null,
  });
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [toDashboard, setToDashboard] = useState(false);

  const handleInputChange = (e) => {
    setCredentials((prevCredential) => ({
      ...prevCredential,
      [e.target.name]: e.target.value,
    }));
  };

  const validate = () => {
    let errors = {};
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;
    if (!credentials.email_address) {
      errors.email_address = 'Email Cannot be blank';
    } else if (!regex.test(credentials.email_address)) {
      errors.email_address = 'Invalid email format';
    }
    if (!credentials.password) {
      errors.password = 'Password Cannot be blank';
    } else if (credentials.password.length < 4) {
      errors.password = 'Password must be more than 4 characters';
    }
    if (Object.keys(errors).length === 0) {
      return true;
    }
    setFormErrors(errors);
    return false;
  };
  const handleLogin = async (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (validate()) {
      setFormErrors({});
      const response = await login(credentials);
      console.log('response', response);
      setIsLoading(false);
      if (response?.status === 422) {
        setFormErrors({ error: response.data.message });
      } else if (response?.status === 200) {
        localStorage.setItem(
          'nasims-test-admin',
          JSON.stringify(response.data),
        );
        setToDashboard(true);
        window.location.reload();
      } else {
        setFormErrors({ error: 'Server Error' });
      }
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    if (toDashboard || getCurrentUser()) {
      history.push(APPLICANTS);
    }

    // eslint-disable-next-line
  }, []);
  console.log('this is working!!!!');
  return (
    <LoginContainer>
      <MetaTags>
        <title>NASIMS | Agent Login</title>
        <meta name='description' content='Nasims Admin Login Portal' />
        <meta property='og:title' content='Nasims' />
        <meta property='og:image' content='/testAdminBg.png' />
      </MetaTags>
      <div className='login-content'>
        <LoginContentContainer logo={Logo}>
          <div className='logo' />
          <LoginHeaderText>Customer Service Agent</LoginHeaderText>
          <LoginText style={{ marginBottom: '40px' }}>
            Welcome!! Kindly enter your username and password.
          </LoginText>
          {Object.keys(formErrors).length > 0 &&
            Object.keys(formErrors).map((err, id) => (
              <DangerText key={id}>{formErrors[err]}</DangerText>
            ))}
          <form onSubmit={handleLogin} className='form-group'>
            <CustomInput
              placeholder='Enter your email address'
              type='text'
              name='email_address'
              handleChange={handleInputChange}
              required
            />
            <CustomInput
              placeholder='Enter password'
              type='password'
              name='password'
              handleChange={handleInputChange}
              required
            />
            <div className='text-dec-none'>
              <CustomButton
                style={{ marginTop: '24px' }}
                text='Sign in'
                type='submit'
                isLoading={isLoading}
              />
            </div>
          </form>
          <Link to='/forgot-password' className='text-dec-none'>
            <DangerText>Forgot password?</DangerText>
          </Link>
        </LoginContentContainer>
      </div>
    </LoginContainer>
  );
};

export default Login;
