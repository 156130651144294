import React, {useContext, useEffect, useMemo, useState} from "react";
import {ContainerStyle} from "../UpdateDetails/style";
import DropDown from "../DropDown/DropDown";
import CustomButton from "../CustomButton/CustomButton";
import axios from "axios";
import {useHistory} from "react-router-dom";
import AuthContext from "../../context/authContext";
import CustomUpload from "../CustomUpload";
import CustomInput from "../CustomInput/CustomInput";
import CustomCheckBox from "../CustomCheckBox/CustomCheckBox"
import {apiClient} from "../../service";
import {emitToast} from "../../Register/Register";

const Process = ({handleClose}) => {
    const [formErrors, setFormErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [form, setForm] = useState({})
    const history = useHistory()
    const [shortListState, setShortListState] = useState(0);
    const [priorityState, setPriorityState] = useState(0);
    const [beneficiaryState, setBeneficiaryState] = useState(0);
    const [assignedState, setAssignedState] = useState(0);
    const [state, setState] = React.useState({ selections: [] });

    const { getCurrentUser } = useContext(AuthContext)
    const user = getCurrentUser()
    const [showUpload, setShowUpload] = useState(true);

    if (!user) {
        history.push('/login')
    }

    let source = axios.CancelToken.source()
    let config = { cancelToken: source.token}

    
const options = ['Priority', 'Shortlist', 'Move To Beneficiary', 'Assign Test Score']


    useEffect(() => {
        return () => {
            source.cancel("Unmounted")
        }
    }, []);

    const handleCheckboxChange = (key) => {
        let sel = state.selections
        let find = sel.indexOf(key)
        if (find > -1) {
          sel.splice(find, 1)
        } else {
          sel.push(key)
        }
    
        setState({
          selections: sel,
        })

        console.log(state.selections)
        if(state.selections.includes('Priority')) {
            // 'Priority', 'Shortlist'
            setPriorityState(1)
        }

        if(state.selections.includes('Shortlist')) {
            setShortListState(1)
        }

        if(state.selections.includes('Move To Beneficiary')) {
            setBeneficiaryState(1)
        }

        if(state.selections.includes('Assign Test Score')) {
            setAssignedState(1)
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setIsLoading(true)
        // switch (form['process']) {
        //     case 'Prioritize':
        //         await handlePrioritize('upload')
        //         break;
        //     case 'Send to beneficiaries':
        //         await handlePrioritize('shortlist')
        //         break;
        //     case 'Pass to Physical Verification Result':
        //         await migrate()
        //         break;
        //     default:
        //         return
        // }
        const data = new FormData()
        data.append('file', form['priority_list'])
        data.append('priority', priorityState)
        data.append('shortlist', shortListState)
        data.append('move_to_beneficiary', beneficiaryState)
        data.append('assign_test_score', assignedState)
        try{
            const response = await apiClient.post(
                '/run-excel-upload',
                data,
                config
            )
            setIsLoading(false)
            if (response.status === 200) {
                console.log(response)
                emitToast('success', 'Action successfully executed')
                handleClose()
            }
        } catch (error) {
            setIsLoading(false)
            setFormErrors({error: error.response?.data?.message})
            console.log(error)
            emitToast('danger', 'Error in execution.')
        }
    }

    const migrate = async () => {
        setIsLoading(true)
        const data = new FormData()
        data.append('ccs_agent_id', user.data.name)
        try {
            const response = await apiClient.post(
                '/migrate',
                data,
                config
            )
            setIsLoading(false)
            if (response.status === 200) {
                console.log(response)
                emitToast('success', 'Action successfully executed')
                handleClose()
            }
        } catch (error) {
            setIsLoading(false)
            setFormErrors({error: error.response?.data?.message})
            console.log(error)
            emitToast('danger', 'Error in execution.')
        }
    }
    const handleProcessChange = (name, value) => {
        if(value === "...") {
            setShowUpload(false)
            setForm({
                ...form,
                [name]: null
            })
            return
        }

        if(value === "Prioritize" || value === "Send to beneficiaries") {
            setShowUpload(true)
        } else {
            setShowUpload(false)
        }

        setForm({
            ...form,
            [name]: value
        })
    }


    const validatePriority = () => {
        let errors = {}
        const {priority_list} = form
        if (!priority_list) {
            errors.priorityList = 'Upload a valid excel file'
        }

        if (Object.keys(errors).length === 0) {
            return true
        }
        setFormErrors(errors)
        return false
    }

    const handlePrioritize = async (action) => {
        setIsLoading(true)
        if(validatePriority()) {
            const data = new FormData()
            data.append('action', action)
            data.append('ccs_agent_id', user.data.name)
            data.append('priority_list', form['priority_list'])
            try {
                const response = await apiClient.post(
                    '/priority-list',
                    data,
                    config
                )
                setIsLoading(false)
                if (response.status === 200) {
                    console.log(response)
                    emitToast('success', 'Action successfully executed')
                    handleClose()
                }
            } catch (error) {
                setIsLoading(false)
                setFormErrors({error: error.response?.data?.message})
                console.log(error)
                emitToast('danger', 'Error in execution.')
            }
        } else {
            setIsLoading(false)
        }
    }
    const processes = useMemo(() => {
        return [
            "...",
            "Prioritize",
            "Pass to Physical Verification Result",
            "Send to beneficiaries"
        ]
    }, []);
    return (
        <ContainerStyle>
            <h2 className='title'>Applicant's Process Actions</h2>
            {Object.keys(formErrors).length > 0 &&
            Object.keys(formErrors).map((err, id) => (
                <p style={{ color: 'red' }} key={id}>
                    {formErrors[err]}
                </p>
            ))}
            <form onSubmit={handleSubmit}>
                {/* <div className="row">
                    <div className="input__group">
                        <label htmlFor="process">Select Process</label>
                        <DropDown
                            options={processes}
                            handleChange={handleProcessChange}
                            name='process'
                            height='45px'
                            radius='5px'
                        />
                    </div>
                    <div className="input__group"/>
                </div> */}
                {showUpload && (
                    <div className="row">
                        <div className="input__group">
                            <label htmlFor="priority_list">Upload File</label>
                            <CustomUpload setState={setForm} state={form} name={"priority_list"}/>
                            {options.map((option) => (
                                <div>
                                <CustomCheckBox
                                    key={option}
                                    labelText={option}
                                    handleChange={() => handleCheckboxChange(option)}
                                    checked={state.selections.includes(option)}
                                ></CustomCheckBox>
                                </div>

                                ))}
                        </div>
                        {/*<div className="input__group"/>*/}
                    </div>
                )}
                <div className="button__container">
                    <CustomButton
                        text='Submit'
                        noshadow
                        border='3px'
                        width='360px'
                        nomargin
                        style={{ marginBottom: '0px' }}
                        type='submit'
                        isLoading={isLoading}
                    />
                </div>
            </form>
        </ContainerStyle>
    )
}

export default Process
