import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import AuthContext from '../../context/authContext'
import { apiClient } from '../../service'
import CustomButton from '../CustomButton/CustomButton'
import DropDown from '../DropDown/DropDown'
import { Style } from './updateCenter.style'

const UpdateTrainingCenter = ({ handleClose, applicant, refetch, trainingCenters, trainingCentersList }) => {
  const [state, setState] = useState(false)
  const history = useHistory()
  const { getCurrentUser } = useContext(AuthContext)
  const user = getCurrentUser()
  const [formErrors, setFormErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [updateForm, setUpdateForm] = useState({})

  if (!user) {
    history.push('/login')
  }



  const validate = () => {
    let errors = {}
    if (state.password.trim() === '') {
      errors.password = 'Password cannot be empty'
    } else if (state.password.length < 4) {
      errors.password = 'Password must be more than 4 characters'
    } else if (state.password !== state.confirmPassword) {
      errors.password = 'Password mismatch'
    }
    if (Object.keys(errors).length === 0) {
      return true
    }
    setFormErrors(errors)
    return false
  }

  const handleCreate = async e => {
    e.preventDefault()
    console.log(state)
    setIsLoading(true)
    setFormErrors({})

    try {
        const response = await apiClient.post(
          '/nbuild/assign/centres',
          updateForm
        )
        setIsLoading(false)
        if (response.status === 200) {
           setState(true)
           console.log(state)
          refetch()
        }
      } catch (error) {
        setIsLoading(false)
        setFormErrors({erorr: error.response?.data.message})
        console.log(error)
      }
  }

//   {
//     "name" : "NPWR/2020/000845276",
//     "training_center_id": "NPWR/TRN/000274"
// }

  const handleInputChange = ({ target }) => {
    setState(prevState => ({ ...prevState, [target.name]: target.value }))
  }

  const handleTrainingCenter = (name, value) => {
    let data = {};
    const foundCenter = trainingCentersList.find(center => center.centre === value)
    data.name = applicant.name
    data.training_center_id = foundCenter.name

    console.log(data)

    setUpdateForm(data)
  }
  return (
    <Style.Container>
      {state ? (
        <>
          <div className='success-group'>
            <div className='success-logo'></div>
            <h2>Training Center Successful</h2>
            <p>
              You have successfully updated the training center for this beneficiary.
            </p>
            <CustomButton
              text='Close'
              noshadow
              border='3px'
              width='100%'
              nomargin
              style={{ marginBottom: '0px' }}
              handleButtonClick={handleClose}
            />
          </div>
        </>
      ) : (
        <form onSubmit={handleCreate}>
          <h2 className='title'>Update Training Center</h2>
          <div className='input-group mbut'>
            <p className='label'>Select Center</p>
            <DropDown
                options={trainingCenters}
                label={''}
                width='100%'
                handleChange={handleTrainingCenter}
                name='training_center'
                // reset={reset}
                // setReset={setReset}
            />
          </div>
          <CustomButton
            text='Update'
            noshadow
            border='3px'
            width='100%'
            nomargin
            style={{ marginBottom: '0px' }}
            type='submit'
            isLoading={isLoading}
          />
        </form>
      )}
    </Style.Container>
  )
}

export default UpdateTrainingCenter
