import styled from "styled-components";

export const AutoCompleteStyle = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  
  .suggestions, .no-suggestions{
    position: absolute;
    top: 120%;
    width: 100%;
    left: 0;
    background-color: white;
  }
  
  .suggestions {
    border: 1px solid #999;
    border-top-width: 0;
    list-style: none;
    margin-top: 0;
    max-height: 143px;
    overflow-y: auto;
    padding-left: 0;

    li {
      padding: 0.5rem;
    }
  }

  .suggestion-active,
  .suggestions li:hover {
    background-color: #008f68;
    color: #fae042;
    cursor: pointer;
    font-weight: 700;
  }

  .suggestions li:not(:last-of-type) {
    border-bottom: 1px solid #999;
  }
`