import React, { useState, useContext } from 'react'
import {
  LoginContainer,
  LoginContentContainer,
  LoginHeaderText,
  LoginText,
  DangerText
} from './Register.style'
import Logo from '../images/logo.png'
import CustomButton from '../components/CustomButton/CustomButton'
import CustomInput from '../components/CustomInput/CustomInput'
import AuthContext from '../context/authContext'
import MetaTags from 'react-meta-tags'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import DropDown from "../components/DropDown/DropDown";

export const emitToast = (type, message) => {
  if (type === 'success') {
    toast.success(message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  } else {
    toast.error(message, {
      position: 'top-center',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined
    })
  }
}

const Register = () => {
  const { register } = useContext(AuthContext)

  const [credentials, setCredentials] = useState({
    email: '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    role: ''
  })
  const [formErrors, setFormErrors] = useState({})
  const [isLoading, setIsLoading] = useState(false)

  const handleInputChange = e => {
    setCredentials(prevCredential => ({
      ...prevCredential,
      [e.target.name]: e.target.value
    }))
  }

  const validate = () => {
    let errors = {}
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
    if (credentials.email === '') {
      errors.email = 'Email Cannot be blank'
    } else if (!regex.test(credentials.email)) {
      errors.email = 'Invalid email format'
    }
    if (credentials.firstName === '') {
      errors.firstName = 'First name required'
    }
    if (credentials.lastName === '') {
      errors.lastName = 'Last name required'
    }
    if (credentials.role === '') {
      errors.role = 'Select agent role'
    }
    if (credentials.password === '') {
      errors.password = 'Password Cannot be blank'
    } else if (credentials.password.length < 4) {
      errors.password = 'Password must be more than 4 characters'
    } else if (credentials.password !== credentials.confirmPassword) {
      errors.password = 'Password mismatch'
    }
    if (Object.keys(errors).length === 0) {
      return true
    } else {
      emitToast('error', 'Invalid details provided')
    }
    setFormErrors(errors)
    return false
  }

  const roles = [
      "Select Agent Role",
      "regular",
      "admin",
      "super-admin",
  ]

  const handleRoleChange = (name, value) => {
    if(value === "Select Agent Role"){
      setCredentials({
        ...credentials,
        [name]: ''
      })

      return
    }
    setCredentials({
      ...credentials,
      [name]: value
    })
  }
  const handleLogin = async e => {
    setIsLoading(true)
    e.preventDefault()
    if (validate()) {
      setFormErrors({})
      const response = await register(credentials)
      setIsLoading(false)
      if (response?.status === 422) {
        setFormErrors({ error: response.data.message })
        emitToast('error', 'Unable to register agent')
      } else if (response?.status === 201) {
        emitToast('success', 'Agent Registration successful')
        setCredentials(prev => ({
          ...prev,
          email: '',
          password: '',
          confirmPassword: '',
          firstName: '',
          lastName: '',
          role: ''
        }))
      } else {
        setFormErrors({ error: response.data.message })
        emitToast('error', 'Unable to register agent')
      }
    } else {
      setIsLoading(false)
    }
  }
  return (
    <LoginContainer>
      <MetaTags>
        <title>NASIMS | Agent Register</title>
        <meta name='description' content='Nasims Admin Registration Portal' />
        <meta property='og:title' content='Nasims' />
        <meta property='og:image' content='/testAdminBg.png' />
      </MetaTags>
      <div className='login-content'>
        <LoginContentContainer logo={Logo}>
          <div className='logo' />
          <LoginHeaderText>Customer Service Agent</LoginHeaderText>
          <LoginText style={{ marginBottom: '40px' }}>
            Welcome!! Kindly register a new agent.
          </LoginText>
          {Object.keys(formErrors).length > 0 &&
            Object.keys(formErrors).map((err, id) => (
              <DangerText key={id}>{formErrors[err]}</DangerText>
            ))}
          <form onSubmit={handleLogin} className='form-group'>
            <CustomInput
              placeholder='Enter First name'
              type='text'
              name='firstName'
              value={credentials.firstName}
              handleChange={handleInputChange}
              required
            />
            <CustomInput
              placeholder='Enter Last name'
              type='text'
              name='lastName'
              value={credentials.lastName}
              handleChange={handleInputChange}
              required
            />
            <CustomInput
              placeholder='Enter email address'
              type='text'
              name='email'
              value={credentials.email}
              handleChange={handleInputChange}
              required
            />
            <CustomInput
              placeholder='Enter password'
              type='password'
              name='password'
              value={credentials.password}
              handleChange={handleInputChange}
              required
            />
            <CustomInput
              placeholder='Confirm password'
              type='password'
              name='confirmPassword'
              value={credentials.confirmPassword}
              handleChange={handleInputChange}
              required
            />
            <DropDown
                options={roles}
                handleChange={handleRoleChange}
                name='role'
                height='45px'
                radius='5px'
            />
            <div className='text-dec-none'>
              <CustomButton
                style={{ marginTop: '24px' }}
                text='Register'
                type='submit'
                isLoading={isLoading}
              />
            </div>
          </form>
        </LoginContentContainer>
      </div>
      <ToastContainer
        position='bottom-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </LoginContainer>
  )
}

export default Register
