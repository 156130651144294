import React, { useState, useRef, useEffect, useContext } from 'react'
import { Style } from './Layout.style'
import Logo from '../images/logo.png'
import Avatar from '../images/avatar.svg'
import Arrow from '../images/arr.svg'
import { capitalize, join } from '../helpers'
import AuthContext from '../context/authContext'
import { useHistory } from 'react-router-dom'

const Layout = ({ children }) => {
  let options = [{ month: 'short' }, { day: 'numeric' }, { year: 'numeric' }]
  let date = join(new Date(), options, ',')
  const menuRef = useRef()
  const { getCurrentUser, logout } = useContext(AuthContext)
  const history = useHistory()

  if (!getCurrentUser()) {
    history.push('/login')
  }
  const user = getCurrentUser()
  const userData = user ? user.data : {}

  const [open, setOpen] = useState(false)
  const handleOpen = () => {
    setOpen(!open)
  }
  useEffect(() => {
    if (open) {
      window.addEventListener('click', backDropHandler)
    }

    return () => window.removeEventListener('click', backDropHandler)
  }, [open])

  const backDropHandler = e => {
    if (!menuRef?.current?.contains(e.target)) {
      setOpen(false)
    }
  }

  return (
    <Style.Container>
      <Style.Header logo={Logo}>
        <div className='logo-date-group'>
          <div className='logo-group'>
            <div className='logo' />
            <h1 className='text'>NASIMS</h1>
          </div>
          <p>{date}</p>
        </div>
        <div className='avater-group' onClick={handleOpen}>
          <img src={Avatar} alt='User' />
          <p className='name'>{`${capitalize(userData?.first_name)} ${capitalize(
            userData?.last_name
          )}`}</p>
          <img src={Arrow} alt='' />
          {open ? (
            <div
              className='logout'
              onClick={() => {
                logout()
              }}
              ref={menuRef}
            >
              <p>Logout</p>
            </div>
          ) : null}
        </div>
      </Style.Header>
      <Style.Content>{children}</Style.Content>
    </Style.Container>
  )
}

export default Layout
