import { BrowserRouter as Router } from 'react-router-dom'
import AuthProvider from './context/AuthProvider'
import Index from './Index/Index'
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

function App () {
  return (
    <QueryClientProvider client={queryClient}>
      <AuthProvider>
        <Router>
          <Index />
        </Router>
      </AuthProvider>
    </QueryClientProvider>
  )
}

export default App
