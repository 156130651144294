import styled from 'styled-components'

export const Style = {
  Container: styled.div`
    position: relative;
    width: 100%;
    height: 40px;
    cursor: pointer;
  `,
  InputWrapper: styled.div`
    width: 100%;
    background: #ffffff;
    border: 1px solid #e0dede;
    box-sizing: border-box;
    border-radius: 2px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 28.61px;
    p {
      font-family: Rubik;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 28px;

      color: #777474;
    }
  `,
  Icon: styled.img`
    position: absolute;
    top: 25%;
    right: 21.9px;
  `,
  Label: styled.h2`
    font-family: ApercuPro;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;

    color: #767676;
  `,
  Wrapper: styled.div`
    display: flex;
    flex-direction: column;

    width: ${({ width }) => (width ? width : '100%')};
  `
}

export const StyleContainer = styled.div`
  .react-datepicker-wrapper {
    width: 100%;
  }
`
