export const capitalize = s => {
  if (typeof s !== 'string') return ''
  return s.charAt(0).toUpperCase() + s.slice(1).toLowerCase()
}

export const join = (date, options, separator) => {
  function format (m) {
    let f = new Intl.DateTimeFormat('en', m)
    return f.format(date)
  }
  let r = options.map(format)
  let g = [[r[0], r[1]], r[2]]
  g[0] = g[0].join(' ')
  return g.join(`${separator} `)
}

export const objToQueryString = obj => {
  const keyValuePairs = []
  for (const key in obj) {
    keyValuePairs.push(
      encodeURIComponent(key) + '=' + encodeURIComponent(obj[key])
    )
  }
  return keyValuePairs.join('&')
}
