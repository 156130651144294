import React from 'react'
import Arrow from '../../images/Arrow.svg'
import BackArrow from '../../images/backArrow.svg'
import Loader from '../Loader/Loader'
import { CustomButtonStyle } from './CustomButton.style'

const CustomButton = ({
  text,
  style,
  handleButtonClick,
  nowarrow,
  nomargin,
  width,
  height,
  noshadow,
  border,
  size,
  lineHeight,
  type,
  isLoading,
  disabled,
  backArrow,
  notCentered
}) => {
  return (
    <CustomButtonStyle
      width={width}
      nomargin={nomargin}
      noshadow={noshadow}
      border={border}
      onClick={handleButtonClick}
      style={style}
      height={height}
      size={size}
      lineHeight={lineHeight}
      type={type}
      disabled={isLoading || disabled}
      notCentered={notCentered}
    >
      <div className='align-elem'>
        {backArrow ? (
          <img src={BackArrow} alt='' style={{ marginRight: '9px' }} />
        ) : (
          ''
        )}
        {isLoading ? <Loader isComponent /> : text}
        {nowarrow ? (
          <img src={Arrow} alt='' style={{ marginLeft: '11.59px' }} />
        ) : (
          ''
        )}
      </div>
    </CustomButtonStyle>
  )
}

export default CustomButton
