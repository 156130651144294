import React, { useContext, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";
import CreatePassword from "../components/CreatePassword/CreatePassword";
import UpdateTrainingCenter from "../components/UpdateTrainingCenter/UpdateTrainingCenter";
import CustomButton from "../components/CustomButton/CustomButton";
import Modal from "../components/Modal/modal.component";
import { ROUTES } from "../constants";
import { capitalize, join } from "../helpers";
import Layout from "../Layout/Layout";
import { apiClient } from "../service";
import { Style } from "./applicants.style";
import { useQuery } from "react-query";
import verified from "./icons/verified.svg";
import editIcon from "../images/edit.svg";
import UpdateDetails from "../components/UpdateDetails";
import Successful from "../components/UpdateDetails/Successful";
import Biometrics from "../components/UpdateDetails/Biometrics";
import AuthContext from "../context/authContext";
import { emitToast } from "../Register/Register";
import { ToastContainer } from "react-toastify";
import Process from "../components/Process";
import BankDetails from "../components/UpdateDetails/BankDetails";
import ClearBVN from "../components/UpdateDetails/ClearBVN";

const formatString = (string, capit = false) => {
  string = string ? string.trim() : string;
  return string && string !== ""
    ? capit
      ? capitalize(string)
      : string
    : "N/A";
};
const formatName = (firstName, lastName) => {
  return `${capitalize(firstName)} ${capitalize(lastName)}`;
};

const formatDate = (date) => {
  let options = [{ day: "numeric" }, { month: "short" }, { year: "numeric" }];
  return join(new Date(date), options, ",");
};

const Details = () => {
  const [show, setShow] = useState(false);
  const [bankShow, setBankShow] = useState(false);
  const [editDetails, setEditDetails] = useState(false);
  const [completed, setCompleted] = useState(false);
  const [biometrics, setBiometrics] = useState(false);
  const [biometricsSuccess, setBiometricsSuccess] = useState(false);
  const [showUpdateModal, setUpdateModal] = useState(false);
  const [trainingCenters, setTrainingCenters] = useState([]);
  const history = useHistory();
  const { APPLICANTS } = ROUTES;
  const query = new URLSearchParams(useLocation().search);
  const [applicantDetails, setApplicantDetails] = useState({});
  const [ppa, setPPA] = useState({});
  const [pvs, setPVS] = useState(null);
  const [passwordRests, setPasswordReset] = useState([]);
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  if (!user) {
    history.push("/login");
  }
  const [isPassingVerification, setIsPassingVerification] = useState(false);
  const [marking, setMarking] = useState(false);
  const [nBuildMarking, setNBuildMarking] = useState(false);
  const [isClearingBVN, setIsClearingBVN] = useState(false);
  const [clearBankDetails, setClearBankDetails] = useState(false);
  const { isLoading, error, data, refetch } = useQuery(
    "applicant-details",
    async () => {
      try {
        const { data } = await apiClient.get(
          `/applicants/details?name=${query.get("name")}`
        );
        console.log(data);
        return data;
      } catch (error) {
        console.log(error);
        throw new Error(error);
      }
    }
  );

  useEffect(() => {
    if (data) {
      setApplicantDetails(data.applicant);
      setPasswordReset(data.password_resets);
      setPPA(data.ppa);
      setPVS(data.physical_verification_status);
    }
  }, [data]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    fetchTrainingCenters();
  }, []);

  const fetchTrainingCenters = async () => {
    const response = await apiClient.get("/nbuild/centres");

    setTrainingCenters(response.data.data);
  };
  const handleClose = () => {
    setShow(false);
  };

  const handleOpen = () => {
    setShow(true);
  };
  const handleBankOpen = () => {
    setBankShow(true);
  };

  const handleBack = () => {
    history.push(APPLICANTS);
  };

  const handleClearBVN = () => {
    setIsClearingBVN(true);
  };

  const handleClearBankDetails = () => {
    setClearBankDetails(true);
    apiClient
      .delete("/clear/bank_details", {
        data: {
          name: query.get("name"),
        },
      })
      .then(
        ({ data }) => {
          emitToast("success", data.message || "cleared bank details");
          setClearBankDetails(false);
        },
        (e) => {
          const error = e.response.data.data.length
            ? e.response.data.data[0]
            : "";
          setClearBankDetails(false);
          emitToast("danger", error || "unable to clear bank details");
        }
      );
  };

  const handlePassingVerification = () => {
    setIsPassingVerification(true);
    apiClient
      .post("/applicants/pass/physical/verification", {
        name: query.get("name"),
      })
      .then((res) => {
        setIsPassingVerification(false);
        emitToast("success", "Applicant Verification Pass successful.");
        console.log(res);
      })
      .catch((e) => {
        setIsPassingVerification(false);
        emitToast("danger", "Unable to Pass Applicant Verification");
        console.log(e);
      });
  };
  const handleMarkingAttendance = () => {
    setMarking(true);
    apiClient
      .post("/mark/trainee/presence", {
        name: query.get("name"),
      })
      .then((res) => {
        setMarking(false);
        emitToast("success", "Applicant Attendance Marked.");
        console.log(res);
      })
      .catch((e) => {
        setMarking(false);
        emitToast("danger", "Unable to Mark Applicant Attendance");
        console.log(e);
      });
  };

  const handleMarkingAttendanceForNBuild = () => {
    setNBuildMarking(true);
    apiClient
      .post("/mark/nbuild/presence", {
        name: query.get("name"),
      })
      .then((res) => {
        setNBuildMarking(false);
        emitToast("success", "Applicant Attendance Marked.");
        console.log(res);
      })
      .catch((e) => {
        setNBuildMarking(false);
        emitToast("danger", e.response.data.message);
        console.log(e.response);
      });
  };

  const handleUpdateModalOpen = () => {
    setUpdateModal(true);
  };

  const handleUpdateModalClose = () => {
    setUpdateModal(false);
  };

  const trainingCentersNames = trainingCenters.map((center) => center.centre);

  // console.log(applicantDetails)
  return (
    <Layout>
      <Style.Container>
        <Style.ButtonWrapper image={false}>
          <div className="wrapper">
            <div className="brief-info">
              <div>
                <h2 className="application-id">
                  {formatString(applicantDetails.name)}
                </h2>
                <div className="flag-group">
                  {applicantDetails.shortlisted ? (
                    <div className="shortlisted-botton">
                      <h3>SHORTLISTED</h3>
                    </div>
                  ) : (
                    <div className="not-shortlisted-botton">
                      <h3>NOT SHORTLISTED</h3>
                    </div>
                  )}
                  {applicantDetails.isDeployed && (
                    <div className="shortlisted-botton">
                      <h3>DEPLOYED</h3>
                    </div>
                  )}
                  {applicantDetails.isBeneficiary && (
                    <div className="shortlisted-botton">
                      <h3>BENEFICIARY</h3>
                    </div>
                  )}
                </div>
              </div>
              <div>
                <div className="image"></div>
                <div className="info-group">
                  <h2>
                    {formatString(
                      formatName(
                        applicantDetails.first_name,
                        applicantDetails.surname
                      )
                    )}
                  </h2>
                  {!!applicantDetails.stream && (
                    <p className="mb-12">
                      Stream: {formatString(applicantDetails.stream)}
                    </p>
                  )}
                  <p className="mb-12">
                    Email: {formatString(applicantDetails.email_address)}
                  </p>
                  <div className="bvn mb-12">
                    <p>BVN: {formatString(applicantDetails.bvn)} </p>
                    {applicantDetails.bvn_validated ? (
                      <img src={verified} alt="" />
                    ) : null}
                  </div>
                  <p className="mb-12">
                    Records:{" "}
                    <span
                      className={
                        applicantDetails.deployed === "3"
                          ? "updated"
                          : "not-updated"
                      }
                    >
                      {applicantDetails.deployed === "3"
                        ? "Updated"
                        : "Not Updated"}
                    </span>
                  </p>
                  <p className="mb-12">
                    Score:{" "}
                    <span className={"updated"}>
                      {applicantDetails.scorepercentage
                        ? applicantDetails.scorepercentage + "%"
                        : "Not Taken"}
                    </span>
                  </p>
                  <p className="mb-12">
                    Biometrics cleared:{" "}
                    <span className={"updated"}>
                      {applicantDetails.biometrics_cleared ? "Yes" : "No"}
                    </span>
                  </p>
                  {pvs && (
                    <p className="mb-12">
                      <strong>Physical Verification: </strong>
                      {pvs === "Pass" ? (
                        <span className={"updated"}>Passed</span>
                      ) : pvs === "Fail" ? (
                        <span className={"updated"} style={{ color: "red" }}>
                          Failed
                        </span>
                      ) : (
                        <span
                          className={"updated"}
                          style={{ color: "#f29339" }}
                        >
                          Pending
                        </span>
                      )}
                    </p>
                  )}
                  {applicantDetails.attendance_marked ? (
                    <p className="mb-12">
                      <strong> Attendance Marked: </strong>
                      <span className="updated">Yes</span>
                    </p>
                  ) : (
                    ""
                  )}
                  {user &&
                    user.data.n_knowlege_privilege === 1 &&
                    applicantDetails.attendance_marked === 0 &&
                    applicantDetails.isBeneficiary && (
                      <CustomButton
                        text="Mark Attendance"
                        noshadow
                        border="3px"
                        width="226px"
                        nomargin
                        style={{ marginBottom: "0px" }}
                        handleButtonClick={handleMarkingAttendance}
                        disabled={isLoading || error || marking}
                      />
                    )}
                  {user &&
                    user.data.nbuild_training_center_privilege === 1 &&
                    applicantDetails.isBeneficiary &&
                    applicantDetails.nbuild_attendance_marked === 0 && (
                      <CustomButton
                        text="Mark Attendance"
                        noshadow
                        border="3px"
                        width="226px"
                        nomargin
                        style={{ marginBottom: "0px" }}
                        handleButtonClick={handleMarkingAttendanceForNBuild}
                        disabled={isLoading || error || nBuildMarking}
                      />
                    )}
                  {user &&
                    user.data.nbuild_training_center_privilege === 1 &&
                    applicantDetails.isBeneficiary && (
                      <CustomButton
                        text="Update Training Center"
                        noshadow
                        border="3px"
                        width="226px"
                        nomargin
                        style={{ marginBottom: "0px" }}
                        handleButtonClick={handleUpdateModalOpen}
                        disabled={isLoading || error}
                      />
                    )}
                </div>
              </div>
            </div>
            <div className="button-group">
              <CustomButton
                text="Go Back"
                noshadow
                border="3px"
                width="226px"
                nomargin
                style={{
                  marginBottom: "0px",
                  color: "#2E883E",
                  backgroundColor: "transparent",
                  textAlign: "left",
                  paddingLeft: "18px",
                }}
                backArrow
                notCentered
                handleButtonClick={handleBack}
              />
              <CustomButton
                text="Create Password"
                noshadow
                border="3px"
                width="226px"
                nomargin
                style={{ marginBottom: "0px" }}
                handleButtonClick={handleOpen}
                disabled={isLoading || error}
              />
              {user &&
                user.data.role === "super-admin" &&
                !isLoading &&
                !error &&
                applicantDetails.isBeneficiary && (
                  <>
                    <CustomButton
                      text="Clear Biometrics"
                      noshadow
                      border="3px"
                      width="226px"
                      nomargin
                      handleButtonClick={() => {
                        setBiometrics(true);
                      }}
                      disabled={isLoading || error}
                      style={{
                        marginTop: "-30px",
                        marginBottom: "0px",
                        color: "#2E883E",
                        backgroundColor: "#3cb2511a",
                        textAlign: "left",
                        paddingLeft: "18px",
                      }}
                    />
                    {pvs !== "Pass" && (
                      <CustomButton
                        text="Pass Physical Verification"
                        noshadow
                        border="3px"
                        width="226px"
                        nomargin
                        handleButtonClick={handlePassingVerification}
                        disabled={isLoading || error || isPassingVerification}
                        style={{
                          marginTop: "-30px",
                          marginBottom: "0px",
                          color: "#2E883E",
                          backgroundColor: "#3cb2511a",
                          textAlign: "left",
                          paddingLeft: "18px",
                        }}
                      />
                    )}
                  </>
                )}
              {user &&
                (user.data.role === "super-admin" ||
                  user.data.n_knowlege_privilege === 1) &&
                !isLoading &&
                !error && (
                  <>
                    <CustomButton
                      text="Update Bank Details"
                      noshadow
                      border="3px"
                      width="226px"
                      nomargin
                      style={{ marginTop: "-30px", marginBottom: "0px" }}
                      handleButtonClick={handleBankOpen}
                      disabled={isLoading || error}
                    />
                  </>
                )}
              {user &&
                user.data.role === "super-admin" &&
                !isLoading &&
                !error && (
                  <>
                    <CustomButton
                      text="Clear BVN"
                      noshadow
                      border="3px"
                      width="226px"
                      nomargin
                      style={{ marginTop: "-30px", marginBottom: "0px" }}
                      handleButtonClick={handleClearBVN}
                      disabled={isLoading || error || isClearingBVN}
                    />
                    <CustomButton
                      text="Clear Bank Details"
                      noshadow
                      border="3px"
                      width="226px"
                      nomargin
                      style={{ marginTop: "-30px", marginBottom: "0px" }}
                      handleButtonClick={handleClearBankDetails}
                      disabled={isLoading || clearBankDetails}
                    />
                  </>
                )}
            </div>
          </div>
        </Style.ButtonWrapper>
        <Style.DetailsContainer>
          <div className="details">
            <div className="title__group">
              <h2 className="title">Personal Details</h2>
              {user &&
                user.data.role === "super-admin" &&
                !isLoading &&
                !error && (
                  <button
                    onClick={() => {
                      setEditDetails(true);
                    }}
                  >
                    <img src={editIcon} alt="" />
                    <p>Edit</p>
                  </button>
                )}
            </div>
            <Style.CardContainer className="mbut">
              <div className="personal-details">
                <div className="detail">
                  <p className="heading">Surname</p>
                  <p className="info">
                    {formatString(applicantDetails.surname, true)}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">First Name</p>
                  <p className="info">
                    {formatString(applicantDetails.first_name, true)}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">Other Names</p>
                  <p className="info">
                    {formatString(applicantDetails.other_name, true)}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">Date of Birth</p>
                  <p className="info">
                    {formatString(applicantDetails.date_of_birth)}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">Gender</p>
                  <p className="info">
                    {formatString(applicantDetails.gender)}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">Phone Number</p>
                  <p className="info">
                    {formatString(applicantDetails.phone_number)}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">State of Origin</p>
                  <p className="info">
                    {formatString(applicantDetails.state_of_origin)}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">LGA</p>
                  <p className="info">
                    {formatString(applicantDetails.lga) === "N/A"
                      ? "N/A"
                      : `${formatString(applicantDetails.lga)} LGA`}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">Scheme Name</p>
                  <p className="info">
                    {formatString(applicantDetails.scheme_name)}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">Year of Application</p>
                  <p className="info">
                    {formatString(applicantDetails.year_of_application)}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">Bank Account Name</p>
                  <p className="info">
                    {formatString(applicantDetails.bank_account_name)}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">Bank Name and Bank Account number</p>
                  <p className="info">
                    {formatString(applicantDetails.bank_name)}:{" "}
                    {formatString(applicantDetails.bank_account_number)}
                  </p>
                </div>
              </div>
            </Style.CardContainer>
            <h2 className="title">Residentail Details</h2>
            <Style.CardContainer className="mbut">
              <div className="residentail-details">
                <div className="detail">
                  <p className="heading">Residential Address</p>
                  <p className="info">
                    {formatString(applicantDetails.residential_address)}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">State of Residence</p>
                  <p className="info">
                    {formatString(applicantDetails.state_of_residence)}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">Local Government Area of Residence</p>
                  <p className="info">
                    {formatString(
                      applicantDetails.local_government_of_residence
                    ) === "N/A"
                      ? "N/A"
                      : `${formatString(
                          applicantDetails.local_government_of_residence
                        )} LGA`}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">Postal Code</p>
                  <p className="info">
                    {formatString(applicantDetails.postal_code)}
                  </p>
                </div>
                <div className="detail">
                  <p className="heading">Landmarks (Closest Bus Stop)</p>
                  <p className="info">
                    {formatString(applicantDetails.landmarks, true)}
                  </p>
                </div>
              </div>
            </Style.CardContainer>
            {applicantDetails.category === "Graduates" ? (
              <div>
                <h2 className="title">PPA Details</h2>
                <Style.CardContainer className="mbut">
                  <div className="personal-details">
                    <div className="detail">
                      <p className="heading">Contact Person</p>
                      <p className="info">
                        {formatString(ppa?.contact_person, true)}
                      </p>
                    </div>
                    <div className="detail">
                      <p className="heading">Category</p>
                      <p className="info">
                        {formatString(ppa?.category, true)}
                      </p>
                    </div>
                    <div className="detail">
                      <p className="heading">Organization name</p>
                      <p className="info">
                        {formatString(ppa?.organization_name, true)}
                      </p>
                    </div>
                    <div className="detail">
                      <p className="heading">State</p>
                      <p className="info">{formatString(ppa?.state, true)}</p>
                    </div>
                    <div className="detail">
                      <p className="heading">LGA</p>
                      <p className="info">
                        {formatString(ppa?.local_government)}
                      </p>
                    </div>
                    <div className="detail">
                      <p className="heading">Phone Number</p>
                      <p className="info">{formatString(ppa?.phone_number)}</p>
                    </div>
                    <div className="detail">
                      <p className="heading">Address</p>
                      <p className="info">{formatString(ppa?.address)}</p>
                    </div>
                    <div className="detail">
                      <p className="heading">Programme</p>
                      <p className="info">
                        {formatString(applicantDetails?.programme)}
                      </p>
                    </div>
                    <div className="detail">
                      <p className="heading">Supervisor</p>
                      <p className="info">{formatString(ppa?.supervisor)}</p>
                    </div>
                    <div className="detail">
                      <p className="heading">Supervisor Name</p>
                      <p className="info">
                        {formatString(ppa?.supervisor_name)}
                      </p>
                    </div>
                  </div>
                </Style.CardContainer>
              </div>
            ) : (
              <div>
                <h2 className="title">Training Track Details</h2>
                <Style.CardContainer className="mbut">
                  <div className="personal-details">
                    {applicantDetails.programme_name === "N-Build" ? (
                      <>
                        <div className="detail">
                          <p className="heading">Center</p>
                          <p className="info">
                            {applicantDetails.nbuild_center
                              ? applicantDetails.nbuild_center
                              : "N/A"}
                          </p>
                        </div>
                        <div className="detail">
                          <p className="heading">Center Email</p>
                          <p className="info">
                            {applicantDetails.nbuild_center_email
                              ? applicantDetails.nbuild_center_email
                              : "N/A"}
                          </p>
                        </div>
                        <div className="detail">
                          <p className="heading">Center Head</p>
                          <p className="info">
                            {applicantDetails.nbuild_center_head
                              ? applicantDetails.nbuild_center_head
                              : "N/A"}
                          </p>
                        </div>
                        <div className="detail">
                          <p className="heading">Center ID</p>
                          <p className="info">
                            {applicantDetails.nbuild_center_id
                              ? applicantDetails.nbuild_center_id
                              : "N/A"}
                          </p>
                        </div>
                        <div className="detail">
                          <p className="heading">Center Phone Number</p>
                          <p className="info">
                            {applicantDetails.nbuild_center_phone_number
                              ? applicantDetails.nbuild_center_phone_number
                              : "N/A"}
                          </p>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className="detail">
                          <p className="heading">Center ID</p>
                          <p className="info">
                            {applicantDetails.non_graduate_center_id
                              ? applicantDetails.non_graduate_center_id
                              : "N/A"}
                          </p>
                        </div>
                        <div className="detail">
                          <p className="heading">Center Life Skills</p>
                          <p className="info">
                            {applicantDetails.non_graduate_center_life_skills
                              ? applicantDetails.non_graduate_center_life_skills
                              : "N/A"}
                          </p>
                        </div>
                        <div className="detail">
                          <p className="heading">Center Life Location</p>
                          <p className="info">
                            {applicantDetails.non_graduate_center_location
                              ? applicantDetails.non_graduate_center_location
                              : "N/A"}
                          </p>
                        </div>
                        <div className="detail">
                          <p className="heading">Center Main</p>
                          <p className="info">
                            {applicantDetails.non_graduate_center_main
                              ? applicantDetails.non_graduate_center_main
                              : "N/A"}
                          </p>
                        </div>
                        <div className="detail">
                          <p className="heading">Center Track</p>
                          <p className="info">
                            {applicantDetails.non_graduate_center_track
                              ? applicantDetails.non_graduate_center_track
                              : "N/A"}
                          </p>
                        </div>
                        <div className="detail">
                          <p className="heading">Center Venue</p>
                          <p className="info">
                            {applicantDetails.non_graduate_center_venue
                              ? applicantDetails.non_graduate_center_venue
                              : "N/A"}
                          </p>
                        </div>
                      </>
                    )}
                  </div>
                </Style.CardContainer>
              </div>
            )}
          </div>
          <div className="trail">
            <h2 className="title">Applicant's Payroll</h2>
            <table style={{ width: "100%" }}>
              <thead>
                <tr>
                  <th>Month</th>
                  <th>Year</th>
                  <th>Payment Status</th>
                  <th>Reason</th>
                  <th>Amount</th>
                </tr>
              </thead>
              <tbody>
                {applicantDetails.payments?.map((details, ind) => (
                  <tr key={ind}>
                    <td>{details.month}</td>
                    <td>{details.year}</td>
                    <td>{details.payment_status}</td>
                    <td>
                      {details.status_reason ? details.status_reason : "None"}
                    </td>
                    <td>{details.amount}</td>
                  </tr>
                ))}
                {applicantDetails.payments?.length === 0 ? (
                  <tr>
                    <td align="center" colSpan={5}>
                      No record(s)
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
            <h2 className="title" style={{ marginTop: "60px" }}>
              Audit Trail
            </h2>
            <table>
              <thead>
                <tr>
                  <th>Password Created By</th>
                  <th>Date</th>
                  <th>Password</th>
                </tr>
              </thead>
              <tbody>
                {passwordRests.map((agent, ind) => (
                  <tr key={ind}>
                    <td>
                      {formatName(
                        agent.cc_agent_first_name,
                        agent.cc_agent_last_name
                      )}
                    </td>
                    <td>{formatDate(agent.creation)}</td>
                    <td>{agent.password}</td>
                  </tr>
                ))}
                {passwordRests.length === 0 ? (
                  <tr>
                    <td align="center" colSpan={4}>
                      No record(s)
                    </td>
                  </tr>
                ) : null}
              </tbody>
            </table>
          </div>
        </Style.DetailsContainer>
      </Style.Container>
      <Modal show={show} onClose={handleClose}>
        <CreatePassword
          handleClose={handleClose}
          applicant={applicantDetails}
          refetch={refetch}
        />
      </Modal>
      <Modal show={showUpdateModal} onClose={handleUpdateModalClose}>
        <UpdateTrainingCenter
          handleClose={handleUpdateModalClose}
          applicant={applicantDetails}
          refetch={refetch}
          trainingCenters={trainingCentersNames}
          trainingCentersList={trainingCenters}
        />
      </Modal>
      <Modal
        show={editDetails}
        onClose={() => {
          setEditDetails(false);
        }}
        width="672px"
      >
        <UpdateDetails
          handleClose={() => {
            setCompleted(true);
            setEditDetails(false);
          }}
          applicant={applicantDetails}
          ppa={ppa}
        />
      </Modal>
      <Modal
        show={bankShow}
        onClose={() => {
          setBankShow(false);
        }}
        width="672px"
      >
        <BankDetails
          handleClose={() => {
            setBankShow(false);
          }}
          applicant={applicantDetails}
        />
      </Modal>
      <Modal
        show={isClearingBVN}
        onClose={() => {
          setIsClearingBVN(false);
        }}
        width="672px"
      >
        <ClearBVN
          handleClose={() => {
            setIsClearingBVN(false);
          }}
          applicant={applicantDetails}
        />
      </Modal>
      <Modal
        show={completed}
        onClose={() => {
          setCompleted(false);
        }}
        width="466px"
      >
        <Successful
          handleClose={() => {
            refetch();
            setCompleted(false);
          }}
        />
      </Modal>
      <Modal
        show={biometrics}
        onClose={() => {
          setBiometrics(false);
        }}
        width="466px"
      >
        <Biometrics
          handleClose={() => {
            setBiometricsSuccess(true);
            setBiometrics(false);
          }}
          name={applicantDetails.name}
        />
      </Modal>
      <Modal
        show={biometricsSuccess}
        onClose={() => {
          setBiometricsSuccess(false);
        }}
        width="466px"
      >
        <Successful
          handleClose={() => {
            refetch();
            setBiometricsSuccess(false);
          }}
          isBiometrics
        />
      </Modal>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
};

export default Details;
