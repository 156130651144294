import AuthContext from './authContext';
import axios from 'axios';
import { apiClient } from '../service';
import { BASE_URL } from '../constants';

const AuthProvider = ({ children }) => {
  const login = async (credentials) => {
    try {
      const response = await axios.post(BASE_URL + '/login', credentials);
      console.log(response);
      localStorage.setItem('customer-login', JSON.stringify(response));
      return response;
    } catch (error) {
      console.log(error.response);
      return error.response;
    }
  };
  const register = async (credentials) => {
    try {
      const response = await apiClient.post('/agent-create', credentials);
      console.log(response);
      return response;
    } catch (error) {
      console.log(error.response);
      return error.response;
    }
  };

  const create = async (adminInfo) => {
    try {
      const response = await apiClient.post('admin/new-admin', adminInfo, {
        headers: { Accept: 'multipart/form-data' },
      });
      console.log(response);
      return response.data;
    } catch (error) {
      return error.response.data;
    }
  };

  const logout = () => {
    console.log('entered');
    localStorage.removeItem('nasims-test-admin');
    window.location.reload();
  };

  const getCurrentUser = () => {
    return JSON.parse(localStorage.getItem('nasims-test-admin'));
  };

  const verify = async (email) => {
    try {
      const response = await apiClient.post('admin/password-reset', { email });
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  };
  const changePassword = async (data) => {
    try {
      const response = await apiClient.post('admin/new-password', data);
      console.log(response);
      return response.data;
    } catch (error) {
      console.log(error);
      return error.response.data;
    }
  };

  const value = {
    login,
    logout,
    getCurrentUser,
    create,
    verify,
    changePassword,
    register,
  };
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
