import React, { useContext } from 'react'
import ThemeProvider from '../theme'
import { Switch, Route, Redirect } from 'react-router-dom'
import AuthContext from '../context/authContext.js'
import { routes } from '../routes'
import { ROUTES } from '../constants.js'

const Index = () => {
  const { getCurrentUser } = useContext(AuthContext)
  const { LOGIN, APPLICANTS } = ROUTES
  return (
    <ThemeProvider>
      <Switch>
        {routes.map((route, indx) => {
          if (
            route.path !== LOGIN &&
            route.path.includes(APPLICANTS) &&
            !getCurrentUser() &&
            !route.path.includes('password') &&
            !route.path.includes('reset')
          ) {
            return (
              <Route path={route.path} exact={route.exact} key={indx}>
                <Redirect to={LOGIN} />
              </Route>
            )
          } else {
            if (route.path === '/') {
              return (
                <Route path={route.path} exact={route.exact} key={indx}>
                  <Redirect to={APPLICANTS} />
                </Route>
              )
            } else {
              return (
                <Route
                  path={route.path}
                  exact={route.exact}
                  key={indx}
                  component={route.component}
                />
              )
            }
          }
        })}
      </Switch>
    </ThemeProvider>
  )
}

export default Index
