import React, { useContext, useEffect, useState } from "react";
import { ContainerStyle } from "./style";
import CustomInput from "../CustomInput/CustomInput";
import DropDown from "../DropDown/DropDown";
import CustomButton from "../CustomButton/CustomButton";
import banks from "../../banks";
import { useHistory } from "react-router-dom";
import AuthContext from "../../context/authContext";
import axios from "axios";
import { api2Client } from "../../service";
import { emitToast } from "../../Register/Register";

const BankDetails = ({ applicant, handleClose }) => {
  const banksNames = banks.map((e) => e.name);
  banksNames.unshift("...");
  const [formErrors, setFormErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const history = useHistory();
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  let source = axios.CancelToken.source();
  let config = { cancelToken: source.token };
  const [form, setForm] = useState({
    accountNumber: "",
    accountName: "",
    bankCode: "",
    bankName: "",
    bvn: "",
    name: applicant.name,
  });

  if (!user) {
    history.push("/login");
  }
  const handleInputChange = ({ target }) => {
    setForm({
      ...form,
      [target.name]: target.value,
    });
  };

  const handleBankChange = (name, value) => {
    if (value === "...") {
      setForm({
        ...form,
        bankName: "",
        bankCode: "",
      });
      return;
    }
    const bankDetails = banks.filter((e) => e.name === value)[0];
    setForm({
      ...form,
      bankName: bankDetails.name,
      bankCode: bankDetails.code,
    });
  };

  const validate = () => {
    let errors = {};
    const { bankName, bankCode, accountNumber, bvn } = form;
    const accountNumberRE = /^[0-9]{10}$/;
    if (bankName.trim() === "" || bankCode.trim() === "") {
      errors.bankName = "Select a valid bank";
    }
    if (accountNumber.trim() === "") {
      errors.accountNumber = "Account number required";
    } else if (!accountNumberRE.test(accountNumber)) {
      errors.accountNumber = "Account number cannot be less than 10";
    }
    if (bvn.trim() === "") {
      errors.bvn = "BVN field required";
    }
    if (Object.keys(errors).length === 0) {
      return true;
    }
    setFormErrors(errors);
    return false;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setFormErrors({});
    if (validate()) {
      const data = new FormData();
      data.append("account_number", form.accountNumber.trim());
      data.append("bank_code", form.bankCode.trim());
      data.append("bvn", form.bvn.trim());
      data.append("name", form.name.trim());
      data.append("bank_name", form.bankName.trim());

      try {
        const response = await api2Client.post(
          "/beneficiary/verify/bankAccount",
          data,
          config
        );
        setIsLoading(false);
        if (response.status === 200) {
          emitToast("success", "Bank Account Successfully verified.");
          handleClose();
        }
      } catch (error) {
        setIsLoading(false);
        setFormErrors({ error: error.response?.data?.message });
        console.log(error);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    return () => {
      source.cancel("Unmounted");
    };
  }, []);
  return (
    <ContainerStyle>
      <h2 className="title">Edit applicant/beneficiary details</h2>
      {Object.keys(formErrors).length > 0 &&
        Object.keys(formErrors).map((err, id) => (
          <p style={{ color: "red" }} key={id}>
            {formErrors[err]}
          </p>
        ))}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="input__group">
            <label htmlFor="accountNumber">Account Number</label>
            <CustomInput
              type="number"
              height="45px"
              color="#fff"
              radius="5px"
              nomargin
              handleChange={handleInputChange}
              name="accountNumber"
              id="accountNumber"
              value={form.accountNumber}
            />
          </div>

          <div className="input__group">
            <label htmlFor="firstName">BVN</label>
            <CustomInput
              type="number"
              height="45px"
              color="#fff"
              radius="5px"
              nomargin
              handleChange={handleInputChange}
              name="bvn"
              id="bvn"
              value={form.bvn}
            />
          </div>
        </div>
        <div className="row">
          <div className="input__group">
            <label htmlFor="bank">Bank</label>
            <DropDown
              options={banksNames}
              handleChange={handleBankChange}
              name="sor"
              height="45px"
              radius="5px"
            />
          </div>
        </div>
        <div className="button__container">
          <CustomButton
            text="Submit"
            noshadow
            border="3px"
            width="360px"
            nomargin
            style={{ marginBottom: "0px" }}
            type="submit"
            isLoading={isLoading}
          />
        </div>
      </form>
    </ContainerStyle>
  );
};

export default BankDetails;
