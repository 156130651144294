export const BASE_URL = 'https://api.nasims.ng/api/ccs';
export const _URL = 'https://api.nasims.ng/api';

export const ROUTES = {
  HOME: '/',
  LOGIN: `/login`,
  APPLICANTS: `/applicants`,
  APPLICANTS_DETAILS: `/applicant`,
  REGISTER: `/register`,
};
