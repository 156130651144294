import React, {useContext, useEffect, useState} from "react";
import {SuccessContainerStyle} from "./style";
import CustomButton from "../CustomButton/CustomButton";
import {apiClient} from "../../service";
import axios from "axios";
import AuthContext from "../../context/authContext";
import {useHistory} from "react-router-dom";

const Biometrics = ({handleClose, name}) => {
    const [isLoading, setIsLoading] = useState(false)
    const [formErrors, setFormErrors] = useState({})
    let source = axios.CancelToken.source()
    let config = { cancelToken: source.token}
    const { getCurrentUser } = useContext(AuthContext)
    const user = getCurrentUser()
    const history = useHistory()


    if (!user) {
        history.push('/login')
    }

    const clearBiometrics = async () => {
        setIsLoading(true)
        const data = new FormData()
        data.append('ccs_agent_id', user.data.name)
        data.append('beneficiary_id', name)
        try {
           const response = await apiClient.delete(
               '/applicants/biometrics',
               {...config, data}
           )

            setIsLoading(false)
            if (response.status === 200) {
                handleClose()
            }
        }catch (e) {
            setIsLoading(false)
            setFormErrors({error: e.response?.data.message})
            console.log(e)
        }
    }

    useEffect(() => {
        return () => {
            source.cancel("Unmounted")
        }
    }, []);
    return (
        <SuccessContainerStyle style={{marginTop: '50px', marginBottom: '50px'}}>
            <p className="green__text">Do you want to clear biometrics for this user?</p>
            {Object.keys(formErrors).length > 0 &&
            Object.keys(formErrors).map((err, id) => (
                <p style={{ color: 'red' }} key={id}>
                    {formErrors[err]}
                </p>
            ))}
            <CustomButton
                text='Clear Biometrics'
                noshadow
                border='3px'
                width='100%'
                nomargin
                style={{ marginBottom: '0px', marginTop: '20px' }}
                handleButtonClick={clearBiometrics}
                isLoading={isLoading}
            />
        </SuccessContainerStyle>
    )
}

export default Biometrics