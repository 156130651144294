import {CheckboxContainer } from './CustomCheckBox.style';


const CustomCheckBox = ({ labelText, handleChange, checked }) => {
    return (
        <CheckboxContainer>
            <label>
          <input
            type="checkbox"
            onChange={handleChange}
            checked={checked}
          />
          <span style={{ marginLeft: 8 }}>{ labelText }</span>
        </label>
        </ CheckboxContainer>
    )
}

export default CustomCheckBox;