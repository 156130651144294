import {useEffect, useRef, useState} from "react";
import CustomInput from "../CustomInput/CustomInput";
import {AutoCompleteStyle} from "./style";

const AutoComplete = ({ suggestions, handleInput, defaultValue, reset, setReset }) => {
    const [filteredSuggestions, setFilteredSuggestions] = useState([]);
    const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(0);
    const [showSuggestions, setShowSuggestions] = useState(false);
    const [input, setInput] = useState("");
    const [id, setID] = useState("");
    const inputRef = useRef()

    const backDropHandler = e => {
      if (!inputRef?.current?.contains(e.target)) {
          if(!input) {
              if(!e.path[0].classList.contains("auto-list")) {
                  setID("")
              }
            setShowSuggestions(false);
          } else if((input.toLowerCase() !== defaultValue?.toLowerCase()) && !id){
            // setID("")
              setShowSuggestions(false);
          } else {
              setShowSuggestions(false);
          }
      }
    }

    useEffect(() => {

        window.addEventListener('click', backDropHandler)

        return () => window.removeEventListener('click', backDropHandler)
    }, [input, id])

    const handleClick = (e) => {
        if(input === ""){
            setFilteredSuggestions([...suggestions])
            setActiveSuggestionIndex(0);
            setID("")
            setShowSuggestions(true);
        }
    }

    const onChange = (e) => {
        const userInput = e.target.value;

        // Filter our suggestions that don't contain the user's input
        const unLinked = suggestions.filter(
            (suggestion) =>
                suggestion[1].toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        setInput(e.target.value);
        setFilteredSuggestions(unLinked);
        setActiveSuggestionIndex(0);
        setShowSuggestions(true);
        setID("")
    };
    const onClick = (e, id) => {
        setFilteredSuggestions([]);
        setInput(e.target.innerText);
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
        setID(id)
    };
    const onLGAChange = () => {
        setFilteredSuggestions([]);
        setInput("");
        setActiveSuggestionIndex(0);
        setShowSuggestions(false);
    };

    useEffect(() => {
        // console.log(id, "id --- as")
        handleInput(id)
    }, [id])
    useEffect(() => {
        if(reset){
            onLGAChange()
            setReset(false)
        }
    }, [reset, setReset])

    useEffect(() => {
        if(defaultValue) setInput(defaultValue)
    }, [defaultValue])


    const onKeyDown = e => {
        if (e.keyCode === 13) {
            setInput(filteredSuggestions[activeSuggestionIndex][1]);
            setActiveSuggestionIndex(0);
            setShowSuggestions(false);
        } else if (e.keyCode === 38) {
            if (activeSuggestionIndex === 0) {
                return;
            }
            setActiveSuggestionIndex(activeSuggestionIndex - 1);
        }
        // User pressed the down arrow, increment the index
        else if (e.keyCode === 40) {
            if (activeSuggestionIndex - 1 === filteredSuggestions.length) {
                return;
            }
            setActiveSuggestionIndex(activeSuggestionIndex + 1);
        }
    };

    const SuggestionsListComponent = () => {
        return filteredSuggestions.length ? (
            <ul className="suggestions">
                {filteredSuggestions.map((suggestion, index) => {
                    let className = '';
                    // Flag the active suggestion with a class
                    if (index === activeSuggestionIndex) {
                        className += "suggestion-active";
                    }
                    return (
                        <li className={`${className} auto-list`} key={index} onClick={(e) => onClick(e, suggestion[0])}>
                            {suggestion[1]}
                        </li>
                    );
                })}
            </ul>
        ) : input.toLowerCase() !== defaultValue?.toLowerCase() && !id && (
            <div className="no-suggestions">
                <small><em>No suggestions available!</em></small>
            </div>
        );
    };

    return (
        <AutoCompleteStyle>
            <CustomInput
                type='text'
                height='45px'
                color='#fff'
                radius='5px'
                nomargin
                handleChange={onChange}
                name='ppa'
                id="ppa"
                onKeyDown={onKeyDown}
                value={input}
                onClick={handleClick}
                ref={inputRef}
            />
            {showSuggestions && <SuggestionsListComponent />}
        </AutoCompleteStyle>
    );
};
export default AutoComplete;
