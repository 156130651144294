import styled from 'styled-components'
import avatarICON from '../images/app-avatar.svg'

export const Style = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
  `,
  FilterWrapper: styled.form`
    background: #f4f3f3;
    padding-top: 26px;
    padding-bottom: 87px;
    padding-left: 103px;
    padding-right: 50px;

    .fetching {
      color: #3cb251;
    }

    .heading-group {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    h1.heading {
      font-weight: bold;
      font-size: 30px;
      line-height: 27px;
      color: #3e3f42;
    }

    h2 {
      font-family: ApercuPro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      /* identical to box height, or 169% */

      color: #767676;
    }

    .filter-group {
      display: flex;
      gap: 21px;
      margin-top: 15px;
      align-items: flex-end;
    }

    .wrapper {
      margin-right: 152px;
      display: flex;
      justify-content: space-between;
      .button-group {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 48px;
      }

      .image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #e0e0e0;
        background-image: url(${avatarICON});
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        margin-right: 31px;
      }

      .brief-info {
        .application-id {
          margin-bottom: 20px;
          font-family: ApercuPro;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 42px;
          color: #3e3f42;
        }

        > div {
          display: flex;
          align-items: center;

          .info-group {
            h2 {
              font-family: ApercuPro;
              font-style: normal;
              font-weight: bold;
              font-size: 20px;
              line-height: 42px;
              color: #3e3f42;
              margin: 0px;
              margin-bottom: 15px;
            }

            p {
              font-family: ApercuPro;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: #4f4f4f;
              margin: 0px;

              :first-child {
                margin-bottom: 7px;
              }
            }
          }
        }
      }
    }
  `,
  ButtonWrapper: styled.div`
    background: #f4f3f3;
    padding-top: 26px;
    padding-bottom: 87px;
    padding-left: 103px;
    padding-right: 50px;

    .heading-group {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
    }

    .detail__group {
    }

    h1.heading {
      font-weight: bold;
      font-size: 30px;
      line-height: 27px;
      color: #3e3f42;
    }

    h2 {
      font-family: ApercuPro;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 27px;
      /* identical to box height, or 169% */

      color: #767676;
    }

    .wrapper {
      margin-right: 152px;
      display: flex;
      justify-content: space-between;
      .button-group {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 48px;
      }

      .image {
        width: 150px;
        height: 150px;
        border-radius: 50%;
        background-color: #e0e0e0;
        background-image: url(${avatarICON});
        background-repeat: no-repeat;
        background-position: center;
        border: 1px solid #e0e0e0;
        box-sizing: border-box;
        margin-right: 31px;
      }

      .brief-info {
        .application-id {
          margin-bottom: 20px;
          font-family: ApercuPro;
          font-style: normal;
          font-weight: bold;
          font-size: 24px;
          line-height: 42px;
          color: #3e3f42;
          margin-right: 20px;
        }

        .shortlisted-botton {
          background: rgba(60, 178, 81, 0.2);
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 38px;
          padding-right: 26px;
          padding-left: 26px;

          h3 {
            text-align: center;
            font-family: ApercuPro;
            font-style: normal;
            font-weight: bold;
            font-size: 16px;
            line-height: 24px;
            color: #287e37;
            margin: 0;
          }
        }
        .not-shortlisted-botton {
          background: rgba(235, 87, 87, 0.2);
          border-radius: 10px;
          display: flex;
          justify-content: center;
          align-items: center;
          max-height: 38px;
          padding-right: 26px;
          padding-left: 26px;

          h3 {
            text-align: center;
            font-family: ApercuPro;
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            line-height: 24px;
            color: #eb5757;
            margin: 0;
          }
        }

        > div {
          display: flex;
          align-items: center;
          
          .flag-group{
            display: flex;
            flex-direction: column;
            gap: 10px;
          }

          .info-group {
            h2 {
              font-family: ApercuPro;
              font-style: normal;
              font-weight: bold;
              font-size: 20px;
              line-height: 42px;
              color: #3e3f42;
              margin: 0px;
              margin-bottom: 15px;
            }

            .bvn {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              img {
                margin-left: 5px;
              }
            }

            .updated {
              font-family: ApercuPro;
              font-weight: bold;
              color: #3cb251;
            }
            .not-updated {
              font-family: ApercuPro;
              font-weight: bold;
              color: #eb5757;
            }

            .mb-12 {
              margin-bottom: 12px;
            }
            p {
              font-family: ApercuPro;
              font-style: normal;
              font-weight: normal;
              font-size: 16px;
              line-height: 24px;
              color: #4f4f4f;
              margin: 0px;
              /* margin-bottom: 12px; */
              /* 
              :first-child {
                margin-bottom: 7px;
              } */
            }
          }
        }
      }
    }
  `,
  TableContainer: styled.div`
    padding: 48px 103px 51px 103px;
    h2.heading {
      font-family: ApercuPro;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;

      color: #288855;
      margin-bottom: 37px;
    }
  `,
  DetailsContainer: styled.div`
    padding: 87px 103px 51px 103px;
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    
    

    .mbut {
      margin-bottom: 65px;
    }
    h2.title {
      font-family: ApercuPro;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 24px;
      color: #288855;
      margin: 0;
      margin-bottom: 25px;
    }

    .title__group{
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 25px;
      h2 {
        margin: 0;
      }
      
      button {
        border: none;
        background-color: transparent;
        display: flex;
        gap: 9px;
        
        p {
          font-weight: bold;
          font-size: 20px;
          color: #4F4F4F;
          margin: 0;
        }
        
        &:hover {
          outline: none;
          cursor: pointer;
        }


      }
    }

    p {
      font-family: ApercuPro;
      margin: 0;
      font-style: normal;
      font-weight: normal;
      line-height: 27px;
    }
    p.info {
      font-size: 18px;
      color: #262627;
    }
    p.heading {
      font-size: 16px;
      color: #767676;
    }
    p.table-data {
      font-size: 16px;
      color: #4f4f4f;
    }
    .details {
      flex-basis: 54.21%;
    }

    .trail {
      flex-basis: 40.38%;

      th {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 27px;
        color: #767676;
        text-align: left;

        padding-left: 15px;
        padding-bottom: 20px;
        :first-child {
          padding-right: 33px;
        }
      }
      table {
        border-spacing: 0px 0px;
      }
      tbody {
        tr {
          :nth-child(even) {
            background: #f4f3f3;
          }
          :last-child {
            td {
              border-bottom: 1px solid #f2f2f2;
            }
          }
        }
      }

      td {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 27px;
        color: #4f4f4f;

        text-align: left;

        padding-top: 8px;
        padding-bottom: 8px;
        padding-left: 15px;
        border-top: 1px solid #f2f2f2;

        :first-child {
          border-left: 1px solid #f2f2f2;
          padding-left: 35px;
        }
        :last-child {
          border-right: 1px solid #f2f2f2;
          padding-right: 21px;
        }
      }
    }

    .personal-details {
      width: 100%;
      display: flex;
      flex-wrap: wrap;

      .detail {
        flex-basis: 50%;
        margin-bottom: 30px;
      }
    }

    .residentail-details {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 25px;
      .detail {
        :last-child {
          margin-bottom: 29px;
        }
      }
    }
  `,
  CardContainer: styled.div`
    border: 1px solid #e0e0e0;
    box-shadow: 0px 0px 5px rgba(242, 242, 242, 0.5);
    border-radius: 4px;
    padding-top: 23px;
    padding-left: 45px;
  `
}
