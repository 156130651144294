import React, { useState, useEffect, forwardRef } from 'react'
import { Style } from './DatePicker.style'
import CALENDER from '../../images/calender.svg'
import DatePicker from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'

const CustomDatePicker = ({
  reset,
  setReset,
  width,
  label,
  name,
  handleDate
}) => {
  const [startDate, setStartDate] = useState(null)
  const CustomInput = forwardRef(({ value, onClick }, ref) => (
    <Style.Container onClick={onClick} ref={ref} width={width}>
      <Style.InputWrapper>
        <p>{value}</p>
      </Style.InputWrapper>
      <Style.Icon src={CALENDER} alt='' />
    </Style.Container>
  ))

  const formatDate = date => {
    const d = new Date(date)
    const year = d.getFullYear()
    let day = d.getDate()
    let month = d.getMonth() + 1
    month = month.toString().length < 2 ? `0${month}` : month
    day = day.toString().length < 2 ? `0${day}` : day

    return `${day}-${month}-${year}`
  }

  useEffect(() => {
    if (reset === true) {
      setStartDate(null)
      setReset(false)
    }
    // eslint-disable-next-line
  }, [reset])
  const date = new Date()
  date.setMonth(date.getMonth() - 216)
  const minDate = new Date()
  minDate.setMonth(date.getMonth() - 420)

  return (
    <Style.Wrapper width={width}>
      <Style.Label>{label}</Style.Label>
      <DatePicker
        dateFormat='dd-MM-yyyy'
        selected={startDate}
        maxDate={date}
        minDate={minDate}
        onChange={date => {
          setStartDate(date)
          const formattedDate = formatDate(date)
          handleDate(name, formattedDate)
        }}
        customInput={<CustomInput />}
        peekNextMonth
        showMonthDropdown
        showYearDropdown
        dropdownMode='select'
        isClearable
      />
    </Style.Wrapper>
  )
}

export default CustomDatePicker
