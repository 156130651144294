import styled from 'styled-components'

export const CustomInputStyle = styled.input`
  background: ${({ color }) =>
    color ? color : props => props.theme.inputFieldColor};
  border: 0.5px solid ${props => props.theme.inputFieldBorder};
  box-sizing: border-box;
  border-radius: ${({ radius }) => (radius ? radius : '6px')};
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '45px')};
  margin-bottom: ${({ nomargin }) => (nomargin ? '0' : '16px')};
  padding-left: 20px;
  &::placeholder {
    font-family: ${props => props.theme.primaryFont};
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: #777474;
  }
  &:focus {
    outline: none;
    padding-left: 20px;
  }
`
