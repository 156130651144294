import styled from 'styled-components'

export const TableRow = styled.tr`
  background: #ffffff;
  /* box-shadow: 0px 3px 20px rgba(0, 0, 0, 0.06); */
  height: 90px;

  td:first-child {
    border-left: 1px solid #f2f2f2;
    padding-left: 22px;
  }
  td:last-child {
    border-right: 1px solid #f2f2f2;
    padding-right: 22px;
  }
  td {
    border-top: 1px solid #f2f2f2;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-right: 15px;
  }
`

export const TableContainer = styled.table`
  white-space: nowrap;
  min-width: 100%;
  border-spacing: 0px 0px;
  border-collapse: separate;
  margin-top: 0px;
  thead {
    padding: 0px;
  }

  tbody {
    tr.record {
      :hover {
        cursor: pointer;
        background-color: #868686;

        td {
          p {
            color: white;
          }
        }
      }
    }
    tr {
      :last-child {
        td {
          border-bottom: 1px solid #f2f2f2;
        }
      }
    }
    tr.record:nth-child(even) {
      background-color: #f4f3f3;
      :hover {
        cursor: pointer;
        background-color: #868686;
      }
    }
  }
`

export const TableHeader = styled.tr`
  th {
    padding-bottom: 15px;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 27px;
    color: #767676;
    padding-right: 20px;
    text-align: left;
  }
  th:first-child {
    padding-left: 22px;
  }
  th:last-child {
    padding-right: 22px;
  }
`
export const GreenText = styled.h2`
  margin: 0px;
  font-family: Rubik;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 27px;
  color: ${({ color }) => (color ? color : '#3cb251')};
`

export const SubText = styled.p`
  margin: 0px;
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 21px;
  letter-spacing: 0.171429px;

  color: #777474;
`
export const BulletIcon = styled.img`
  margin-right: 5px;

  margin-top: 15px;
`
export const TableText = styled.p`
  margin: 0px;
  font-family: Rubik;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;

  color: #333333;
  font-weight: ${({ weight }) => (weight ? weight : 'normal')};
  color: ${({ color }) => (color ? color : '#333333')};
`
