import React, { useState, useEffect, useRef } from 'react'
import {
  DropDownContainer,
  DropDownLabel,
  DropDownOption,
  DropDownOptionGroup,
  DropDownSelect,
  DropDownArrow,
  DropDownSelectOption
} from './DropDown.style'
import ARROW from '../../images/drop_down_arrow.svg'

const DropDown = ({
  options,
  label,
  handleChange,
  width,
  name,
  reset,
  setReset,
  height,
  initialValue,
    ...rest
}) => {
  const node = useRef()
  const [isOpen, setIsOpen] = useState(false)
  const [selectedOption, setSelectedOption] = useState(null)
  const toggling = () => setIsOpen(!isOpen)
  const onOptionClicked = value => () => {
    setSelectedOption(value)
    setIsOpen(false)
    if (handleChange && name) {
      handleChange(name, value)
    } else if (handleChange) {
      handleChange()
    }
  }

  useEffect(() => {
    if (reset === true) {
      setSelectedOption(null)
      setReset(false)
    }
    // eslint-disable-next-line
  }, [reset])

  useEffect(() => {
    if (initialValue) {
      if(options.indexOf(initialValue) === -1) return
      onOptionClicked(initialValue)()
    }
    // eslint-disable-next-line
  }, [options, initialValue])

  const handleOutsideClick = e => {
    if (node.current.contains(e.target)) {
      return
    }
    setIsOpen(false)
  }

  useEffect(() => {
    document.addEventListener('mousedown', handleOutsideClick)
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick)
    }
  }, [])
  return (
    <DropDownContainer ref={node}>
      {label && (
          <DropDownLabel>{label}</DropDownLabel>
      )}
      <DropDownSelect width={width} height={height} {...rest}>
        <DropDownSelectOption onClick={toggling}>
          {options?.includes(selectedOption)
            ? selectedOption
            : options[0] || options[0]}
        </DropDownSelectOption>
        <DropDownArrow src={ARROW} onClick={toggling} />
        {isOpen && (
          <DropDownOptionGroup>
            {options?.map((option, id) => {
              return (
                <DropDownOption key={id} onClick={onOptionClicked(option)}>
                  {option}
                </DropDownOption>
              )
            })}
          </DropDownOptionGroup>
        )}
      </DropDownSelect>
    </DropDownContainer>
  )
}

export default DropDown
