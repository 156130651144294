import styled from 'styled-components'

export const LoginContainer = styled.div`
  display: flex;
  height: 100vh;
  .login-content {
    width: 100%;
    background: ${props => props.theme.white};
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  @media only screen and (max-width: 800px) {
    .login-content {
      width: 100%;
      padding-right: 2rem;
      padding-left: 2rem;
    }
  }
`

export const LoginHeaderText = styled.h2`
  font-family: ${props => props.theme.secondaryFont};
  font-style: normal;
  font-weight: 600;
  font-size: 25px;
  line-height: 37px;
  margin-bottom: 10px;
  color: ${props => props.theme.primaryTextColor};
`
export const LoginText = styled.h2`
  font-family: ${props => props.theme.primaryFont};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 27px;
  text-align: center;
  margin-top: 0px;
  color: ${({ otherText }) => (otherText ? '#828282' : '#4f4f4f')};
`
export const DangerText = styled(LoginText)`
  font-size: 14px;
  color: #eb5757;
`

export const LoginContentContainer = styled.div`
  form.form-group {
    width: 342px;
  }
  margin: 59px auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .text-dec-none {
    text-decoration: none;
  }
  .logo {
    background: url(${({ logo }) => logo});
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    height: 99px;
    width: 104px;
    margin-bottom: 50px;
  }
  @media only screen and (max-width: 800px) {
    width: 100%;
  }
`
