import React from 'react'

const defaultButton = props => <button {...props}>{props.children}</button>

const Ellipses = () => {
  return (
    <svg
      width='11'
      height='2'
      viewBox='0 0 11 2'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      style={{ marginRight: '18px' }}
    >
      <path
        d='M0.893125 2C0.786458 2 0.695792 1.968 0.621125 1.904C0.557125 1.82933 0.525125 1.73867 0.525125 1.632V0.48C0.525125 0.373333 0.557125 0.288 0.621125 0.224C0.695792 0.149333 0.786458 0.112 0.893125 0.112H2.04513C2.15179 0.112 2.24246 0.149333 2.31713 0.224C2.39179 0.288 2.42913 0.373333 2.42913 0.48V1.632C2.42913 1.73867 2.39179 1.82933 2.31713 1.904C2.24246 1.968 2.15179 2 2.04513 2H0.893125ZM4.92438 2C4.81771 2 4.72704 1.968 4.65238 1.904C4.58838 1.82933 4.55638 1.73867 4.55638 1.632V0.48C4.55638 0.373333 4.58838 0.288 4.65238 0.224C4.72704 0.149333 4.81771 0.112 4.92438 0.112H6.07638C6.18304 0.112 6.27371 0.149333 6.34838 0.224C6.42304 0.288 6.46038 0.373333 6.46038 0.48V1.632C6.46038 1.73867 6.42304 1.82933 6.34838 1.904C6.27371 1.968 6.18304 2 6.07638 2H4.92438ZM8.95563 2C8.84896 2 8.75829 1.968 8.68363 1.904C8.61963 1.82933 8.58763 1.73867 8.58763 1.632V0.48C8.58763 0.373333 8.61963 0.288 8.68363 0.224C8.75829 0.149333 8.84896 0.112 8.95563 0.112H10.1076C10.2143 0.112 10.305 0.149333 10.3796 0.224C10.4543 0.288 10.4916 0.373333 10.4916 0.48V1.632C10.4916 1.73867 10.4543 1.82933 10.3796 1.904C10.305 1.968 10.2143 2 10.1076 2H8.95563Z'
        fill='#777474'
      />
    </svg>
  )
}

export default class Pagination extends React.Component {
  constructor (props) {
    super()

    this.changePage = this.changePage.bind(this)

    this.state = {
      visiblePages: this.getVisiblePages(null, props.pages)
    }
  }

  //Normal way it was
  // componentWillReceiveProps(nextProps) {
  //   if (this.props.pages !== nextProps.pages) {
  //     this.setState({
  //       visiblePages: this.getVisiblePages(null, nextProps.pages)
  //     });
  //   }

  //   this.changePage(nextProps.page + 1);
  // }

  componentDidUpdate (prevProps) {
    if (prevProps.pages !== this.props.pages) {
      if (this.props.pages !== this.state.visiblePages[-1]) {
        const visiblePages = this.getVisiblePages(
          this.props.page,
          this.props.pages
        )

        this.setState({
          visiblePages: this.filterPages(visiblePages, this.props.pages)
        })
      }
    }
  }

  filterPages = (visiblePages, totalPages) => {
    return visiblePages.filter(page => page <= totalPages)
  }

  getVisiblePages = (page, total) => {
    if (total < 7) {
      return this.filterPages([1, 2, 3, 4, 5, 6], total)
    } else {
      if (page % 5 >= 0 && page > 4 && page + 2 < total) {
        return [1, page - 1, page, page + 1, total]
      } else if (page % 5 >= 0 && page > 4 && page + 2 >= total) {
        return [1, total - 3, total - 2, total - 1, total]
      } else {
        return [1, 2, 3, 4, 5, total]
      }
    }
  }

  changePage (page) {
    const activePage = this.props.page + 1

    if (page === activePage) {
      return
    }

    const visiblePages = this.getVisiblePages(page, this.props.pages)

    this.setState({
      visiblePages: this.filterPages(visiblePages, this.props.pages)
    })

    this.props.onPageChange(page - 1)
  }

  render () {
    const { PageButtonComponent = defaultButton } = this.props
    const { visiblePages } = this.state
    const activePage = this.props.page + 1

    return (
      <div className='Table__pagination'>
        <div className='Table__prevPageWrapper'>
          <PageButtonComponent
            className='Table__pageButton'
            onClick={() => {
              if (activePage === 1) return
              this.changePage(activePage - 1)
            }}
            disabled={activePage === 1}
          >
            {this.props.previousText}
          </PageButtonComponent>
        </div>
        <div className='Table__visiblePagesWrapper'>
          {visiblePages.map((page, index, array) => {
            return (
              <PageButtonComponent
                key={page}
                className={
                  activePage === page
                    ? 'Table__pageButton Table__pageButton--active'
                    : 'Table__pageButton'
                }
                onClick={this.changePage.bind(null, page)}
              >
                {array[index - 1] + 2 < page ? (
                  <div>
                    <Ellipses />
                    {` ${page}`}
                  </div>
                ) : (
                  page
                )}
              </PageButtonComponent>
            )
          })}
        </div>
        <div className='Table__nextPageWrapper'>
          <PageButtonComponent
            className='Table__pageButton'
            onClick={() => {
              if (activePage === this.props.pages) return
              this.changePage(activePage + 1)
            }}
            disabled={activePage === this.props.pages}
          >
            {this.props.nextText}
          </PageButtonComponent>
        </div>
      </div>
    )
  }
}
