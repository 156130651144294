import React, { useContext, useEffect, useMemo, useState } from "react";
import { ContainerStyle } from "./style";
import CustomInput from "../CustomInput/CustomInput";
import DropDown from "../DropDown/DropDown";
import NaijaStates from "../../states.json";
import CustomButton from "../CustomButton/CustomButton";
import AuthContext from "../../context/authContext";
import { useHistory } from "react-router-dom";
import debounce from "lodash.debounce";
import { apiClient } from "../../service";
import AutoComplete from "../AutoComplete";
import axios from "axios";
import CustomDatePicker from "../DatePicker/DatePicker";
import GenericPicker from "../DatePicker/GenericPicker";

const UpdateDetails = ({ handleClose, applicant, ppa }) => {
  String.prototype.capitalize = function () {
    let arr = this.split(" ");
    return arr
      .map((str) => str.charAt(0).toUpperCase() + str.slice(1).toLowerCase())
      .join(" ");
  };
  console.log(applicant, "applicant");
  const states = useMemo(() => {
    return NaijaStates.map((data) => data.state);
  }, []);
  const categories = useMemo(() => {
    return ["Graduates", "Non Graduates"];
  }, []);
  const programmes = useMemo(() => {
    return [
      "N-Teach - NPR",
      "N-Agro - NPR",
      "N-Build - NPR",
      "N-Health - NPR",
      "N-Creative - NPR",
      "N-Tech - NPR",
      "N-Tax - NPR",
    ];
  }, []);
  const subProgrammes = useMemo(() => {
    return [
      "...",
      "Carpentary",
      "Automobile",
      "Plumbing",
      "Scripting",
      "Masonry",
      "Cooking/Catering",
      "Electrical",
      "Mixology",
      "Hardware",
      "Agri-Tech",
      "Animation",
      "House Keeping",
      "Baking/Confectionery",
      "Software ",
    ];
  }, []);
  const [lga, setLga] = useState(["Choose LGA"]);
  const [form, setForm] = useState({
    firstName: applicant.first_name ? applicant.first_name : "",
    surName: applicant.surname ? applicant.surname : "",
    otherName: applicant.other_name ? applicant.other_name : "",
    lga:
      applicant.local_government_of_residence === "Municipal Area Council"
        ? "Abuja Municipal"
        : applicant.local_government_of_residence
        ? applicant.local_government_of_residence.capitalize()
        : "",
    ppa: "",
    category: applicant.category ? applicant.category : "",
    programme: applicant.programme ? applicant.programme : "",
    sub_programme: applicant.sub_programme ? applicant.sub_programme : "",
    date_of_birth: applicant.date_of_birth,
  });
  const [formErrors, setFormErrors] = useState({});
  const [sor, setSOR] = useState(
    applicant.state_of_residence === "FCT" ||
      applicant.state_of_residence === "Abuja"
      ? "Federal Capital Territory"
      : applicant.state_of_residence
      ? applicant.state_of_residence.capitalize()
      : ""
  );
  const [isLoading, setIsLoading] = useState(false);
  const [reset, setReset] = useState(false);
  const [ppaArray, setPPAArray] = useState([]);
  const history = useHistory();
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  let source = axios.CancelToken.source();
  let config = { cancelToken: source.token };

  if (!user) {
    history.push("/login");
  }

  const validate = () => {
    let errors = {};
    const { firstName, surName, lga} = form;
    if (firstName.trim() === "") {
      errors.fisrtName = "First Name cannot be empty";
    }
    if (surName.trim() === "") {
      errors.surName = "Surname cannot be empty";
    }
    if (sor.trim() === "") {
      errors.sor = "Select Valid State";
    }
    if (lga.trim() === "") {
      errors.lga = "Select Valid LGA";
    }
    if (Object.keys(errors).length === 0) {
      return true;
    }
    setFormErrors(errors);
    return false;
  };

  const handleInputChange = ({ target }) => {
    setForm({
      ...form,
      [target.name]: target.value,
    });
  };

  const handleStateChange = (name, value) => {
    const lgResult = NaijaStates.find(
      (data) => data.state === value
    ).lgas.sort();
    setLga(["Choose LGA", ...lgResult.map((lga) => lga.toUpperCase())]);
    if (value === "Federal Capital Territory") {
      setSOR("FCT");
    } else {
      setSOR(value);
    }
    setForm({
      ...form,
      lga: "",
    });
    setReset(true);
  };
  const handleCategoryChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  const handleProgrammeChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };
  const handleSubProgrammeChange = (name, value) => {
    console.log(name);
    if (value === "...") {
      setForm({
        ...form,
        [name]: "",
      });
      return;
    }
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleDateChange = (name, value) => {
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleLgaChange = (name, value) => {
    if (value === "Choose LGA") {
      setForm({
        ...form,
        [name]: "",
      });
      setReset(true);
      return;
    }
    if (value === "Abuja Municipal") {
      setForm({
        ...form,
        [name]: "Municipal Area Council",
      });
      setReset(true);
      return;
    }
    setForm({
      ...form,
      [name]: value,
    });
    setReset(true);
  };
  // console.log(sor, "sor")
  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    // console.log(form, ">>>")

    if (validate()) {
      const data = new FormData();
      const ppa_id = form.ppa.trim() !== "" ? form.ppa.trim() : "";
      data.append("ccs_agent_id", user.data.name);
      data.append("applicant_id", applicant.name);
      data.append("first_name", form.firstName.trim());
      data.append("other_name", form.otherName.trim());
      data.append("last_name", form.surName.trim());
      data.append("ppa_id", ppa_id);
      data.append("state_of_residence", sor.toUpperCase().trim());
      data.append("local_government_of_residence", form.lga.trim());
      data.append("category", form.category.trim());
      data.append("programme", form.programme.trim());
      data.append("sub_programme", form.sub_programme.trim());
      data.append("date_of_birth", form.date_of_birth.trim());
      try {
        const response = await apiClient.post("/applicants/edit", data, config);
        setIsLoading(false);
        if (response.status === 200) {
          handleClose();
        }
      } catch (error) {
        setIsLoading(false);
        setFormErrors({ error: error.response?.data?.message });
        console.log(error);
      }
    } else {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (form.lga === "") return;
    fetchPPA(config);
    return () => {
      source.cancel("Unmounted");
    };
  }, [form.lga]);

  // console.log(sor, "sor")

  const fetchPPA = debounce(async (config) => {
    setIsLoading(true);
    try {
      const { data } = await apiClient.post(
        `/applicants/lgc/ppa`,
        {
          local_government: form.lga,
        },
        config
      );
      setIsLoading(false);
      setPPAArray([
        ...data.data.map((res) => [res.name, res.organization_name]),
      ]);
    } catch (e) {
      setIsLoading(false);
      console.log(e);
    }
  }, 1000);
  return (
    <ContainerStyle>
      <h2 className="title">Edit applicant/beneficiary details</h2>
      {Object.keys(formErrors).length > 0 &&
        Object.keys(formErrors).map((err, id) => (
          <p style={{ color: "red" }} key={id}>
            {formErrors[err]}
          </p>
        ))}
      <form onSubmit={handleSubmit}>
        <div className="row">
          <div className="input__group">
            <label htmlFor="surName">Surname</label>
            <CustomInput
              type="text"
              height="45px"
              color="#fff"
              radius="5px"
              nomargin
              handleChange={handleInputChange}
              name="surName"
              id="surName"
              value={form.surName}
            />
          </div>
          <div className="input__group">
            <label htmlFor="firstName">First name</label>
            <CustomInput
              type="text"
              height="45px"
              color="#fff"
              radius="5px"
              nomargin
              handleChange={handleInputChange}
              name="firstName"
              id="firstName"
              value={form.firstName}
            />
          </div>
        </div>
        <div className="row">
          <div className="input__group">
            <label htmlFor="firstName">Other name</label>
            <CustomInput
              type="text"
              height="45px"
              color="#fff"
              radius="5px"
              nomargin
              handleChange={handleInputChange}
              name="otherName"
              id="otherName"
              value={form.otherName}
            />
          </div>
          <div className="input__group">
            <label htmlFor="sor">State of Residence</label>
            <DropDown
              options={states}
              handleChange={handleStateChange}
              name="sor"
              height="45px"
              radius="5px"
              initialValue={sor}
            />
          </div>
        </div>
        <div className="row">
          <div className="input__group">
            <label htmlFor="lga">LGA of Residence</label>
            <DropDown
              options={lga}
              handleChange={handleLgaChange}
              name="lga"
              height="45px"
              radius="5px"
              initialValue={form.lga}
            />
          </div>
          <div className="input__group">
            <label htmlFor="category">Programme Category</label>
            <DropDown
              options={categories}
              handleChange={handleCategoryChange}
              name="category"
              height="45px"
              radius="5px"
              initialValue={form.category}
            />
          </div>
        </div>
        <div className="row">
          <div className="input__group">
            <label htmlFor="programme">Programmes</label>
            <DropDown
              options={programmes}
              handleChange={handleProgrammeChange}
              name="programme"
              height="45px"
              radius="5px"
              initialValue={form.programme}
            />
          </div>
          <div className="input__group">
            <label htmlFor="sub_programme">Sub Programme</label>
            <DropDown
              options={subProgrammes}
              handleChange={handleSubProgrammeChange}
              name="sub_programme"
              height="45px"
              radius="5px"
            />
          </div>
        </div>
        <div className="row">
          <div className="input__group">
            <label htmlFor="date_of_birth">Date of Birth</label>
            <GenericPicker
              name="date_of_birth"
              handleDate={handleDateChange}
              initialValue={form.date_of_birth}
            />
          </div>
          <div className="input__group">
            <label htmlFor="ppa">PPA</label>
            <AutoComplete
              reset={reset}
              setReset={setReset}
              defaultValue={ppa.organization_name ? ppa.organization_name : ""}
              suggestions={ppaArray}
              handleInput={(id) => setForm({ ...form, ppa: id })}
            />
          </div>
          {/*<div className="input__group"/>*/}
        </div>
        <div className="button__container">
          <CustomButton
            text="Update details"
            noshadow
            border="3px"
            width="360px"
            nomargin
            style={{ marginBottom: "0px" }}
            type="submit"
            isLoading={isLoading}
          />
        </div>
      </form>
    </ContainerStyle>
  );
};

export default UpdateDetails;
