import React, {forwardRef} from 'react'
import { CustomInputStyle } from './CustomInput.style'

const CustomInput = forwardRef(({
  name,
  placeholder,
  type,
  height,
  color,
  radius,
  handleChange,
  required,
  width,
  nomargin,
  clear,
  value,
...rest
}, ref) => {
  return (
    <CustomInputStyle
      placeholder={placeholder}
      type={type}
      height={height}
      color={color}
      radius={radius}
      onChange={handleChange}
      name={name}
      width={width}
      required={required}
      nomargin={nomargin}
      value={clear ? '' : value}
      {...rest}
        ref={ref}
    />
  )
})

export default CustomInput
