import { useEffect } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { apiClient } from '../service'

const useFetch = (filtersParams, pageIndex) => {
  const queryClient = useQueryClient()
  const page = pageIndex + 1
  const fetchRecords = async (index = page) => {
    const formData = new FormData()
    formData.append('page', index)
    const result = []
    const { data } = await apiClient.post(
      `/applicants/fetch?${filtersParams}`,
      formData
    )
    data.applicants?.forEach(datum => {
      const {
        first_name,
        surname: last_name,
        programme,
        state_of_residence,
        email_address: email,
        phone_number,
        name
      } = datum
      result.push({
        first_name,
        last_name,
        application_id: name,
        programme,
        state_of_residence,
        email,
        phone_number,
        name
      })
    })
    const rows = { result, total_pages: data.total_pages, rows: data.total_rows }
    return rows
  }
  const { isLoading, error, data, refetch, isFetching } = useQuery(
    ['applicants-data', pageIndex],
    () => fetchRecords()
  )

  useEffect(() => {
    if (data && data.total_pages && page < data.total_pages) {
      queryClient.prefetchQuery(['applicants-data', page + 1], () =>
        fetchRecords(page + 1)
      )
    }
    // eslint-disable-next-line
  }, [data, page, queryClient])

  return { data, isLoading, error, refetch, isFetching }
}

export default useFetch
