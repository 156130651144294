import React, { useCallback, useState, useEffect } from "react";
import { Style } from "./style";
import { useDropzone } from "react-dropzone";

const getFileType = (type) => {
    switch (type) {
        case "application/pdf":
            return "PDF";
        default:
            return "EXCEL";
    }
};

const getFileSize = (bytes) => {
    if (bytes === 0) return "0 Bytes";

    const k = 1024;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    return parseFloat((bytes / Math.pow(k, i)).toFixed(0)) + " " + sizes[i];
};

const getCustomErrorMessages = (code) => {
    switch (code) {
        case "file-invalid-type":
            return "File must be an Excel document";
        default:
            return "Invalid File Selected";
    }
};

const CustomUpload = ({ setState, state, name }) => {
    const [error, setError] = useState(null);
    const onDrop = useCallback((acceptedFiles) => {
        // Do something with the files
        const [document] = acceptedFiles;
        document &&
        setState({
            ...state,
            [name]: document
        });
    }, []);
    const {
        getRootProps,
        getInputProps,
        isDragActive,
        fileRejections,
    } = useDropzone({
        onDrop,
        multiple: false,
        accept:
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel.sheet.binary.macroEnabled.12, application/vnd.ms-excel, application/vnd.ms-excel.sheet.macroEnabled.12",
    });

    useEffect(() => {
        if (fileRejections.length > 0) {
            const err = fileRejections[0].errors;
            setError(err);
        } else {
            setError(null);
        }
    }, [fileRejections]);

    return (
        <Style.Modal__Container>
            <div className="drag-area-container" {...getRootProps()}>
                <input {...getInputProps()} />
                {isDragActive ? (
                    <>
                        {error?.map((err) => (
                            <p>{getCustomErrorMessages(err.code)}</p>
                        ))}
                        <div className="drag-area">
                            <div className="upload-info">
                                <h1 className="info-title">Drop File Here ...</h1>
                            </div>
                        </div>
                    </>
                ) : state[name] ? (
                    <Style.Drop__Container>
                        <div className="file-container">
                            <div className="drop-info">
                                <h1 className="file-name">{state[name].name}</h1>
                                <div className="info">
                                    <p>{getFileType(state[name].type)}</p>
                                    <p>{getFileSize(state[name].size)}</p>
                                </div>
                            </div>
                        </div>
                    </Style.Drop__Container>
                ) : (
                    <>
                        {error?.map((err) => (
                            <p>{getCustomErrorMessages(err.code)}</p>
                        ))}
                        <div className="drag-area">
                            <div className="upload-info">
                                <h1 className="info-title mb-5">Drag File Here</h1>
                                <p className="info-text">
                                    or{" "}
                                    <a href="#" className="underline">
                                        Browse Files
                                    </a>
                                </p>
                            </div>
                        </div>
                    </>
                )}
            </div>
        </Style.Modal__Container>
    );
};

export default CustomUpload;
