import styled from 'styled-components'

export const ContainerStyle = styled.div`
 display: flex;
  flex-direction: column;
  align-items: center;
  font-family: ApercuPro;


  h2.title{
    margin: 0 0 54px 0;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    color: #333333;
  }
  
  padding-right: 92px;
  padding-left: 92px;
  
  form {
    display: flex;
    flex-direction: column;
    gap: 28px;
    margin-bottom: 58px;
    
    .button__container{
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
    }
    
    .row{
      display: flex;
      gap: 18px;
      flex-grow: 1;
      
      .input__group{
        width: 100%;
        label {
          font-weight: normal;
          font-size: 14px;
          color: #828282;
        }
      }
    }
  }
`

export const SuccessContainerStyle = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 53px 53px 53px;
  gap: 28px;
  
  
  > p {
    font-family: Rubik;
    font-size: 16px;
    color: #485465;
    margin: 0;
    text-align: center;
    width: 300px;
  }

  p.green__text{
    font-family: ApercuPro;
    font-weight: bold;
    font-size: 20px;
    text-align: center;
    color: #288855;
    margin: 0;
    width: 250px;
  }
`