import React, {forwardRef, useState} from "react";
import DatePicker from "react-datepicker";
import {CustomInputStyle} from "../CustomInput/CustomInput.style";
import {StyleContainer} from "./DatePicker.style";

const GenericPicker = ({handleDate, name, initialValue}) => {
    const [startDate, setStartDate] = useState(new Date(initialValue))
    const CustomInput = forwardRef(({ value, onClick }, ref) => (
        <CustomInputStyle
            onClick={onClick}
            type="text"
            height="45px"
            color='#fff'
            radius='5px'
            required
            nomargin
            value={value}
            ref={ref}
            width={"100%"}
            readOnly
        />
    ))

    const formatDate = date => {
        const d = new Date(date)
        const year = d.getFullYear()
        let day = d.getDate()
        let month = d.getMonth() + 1
        month = month.toString().length < 2 ? `0${month}` : month
        day = day.toString().length < 2 ? `0${day}` : day

        return `${year}-${month}-${day}`
    }

    const date = new Date()
    date.setMonth(date.getMonth() - 216)
    const minDate = new Date()
    minDate.setMonth(date.getMonth() - 420)

    return (
        <StyleContainer>
            <DatePicker
                dateFormat='yyyy-MM-dd'
                selected={startDate}
                onChange={date => {
                    setStartDate(date)
                    const formattedDate = formatDate(date)
                    handleDate(name, formattedDate)
                }}
                customInput={<CustomInput />}
                peekNextMonth
                showMonthDropdown
                showYearDropdown
                dropdownMode='select'
                isClearable
            />
        </StyleContainer>


    )
}

export default GenericPicker
