import styled from 'styled-components'
import closeIcon from '../../images/close.svg'

export const Style = {
  ModalOverlay: styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 25;
    background-color: rgba(0, 0, 0, 0.5);
    overflow-y: auto;
  `,
  ModalWrapper: styled.div`
    margin: 76px auto;
    width: 90%;
    max-width: ${({ width }) => (width ? width : '31.38%')};
    height: auto;

    display: flex;
    justify-content: space-between;
    @media (max-width: 768px) {
      flex-direction: column-reverse;
      align-items: flex-end;
      width: 90%;
      max-width: 90%;
    }
  `,
  Modal: styled.div`
    background: white;
    flex-basis: 100%;
    height: 100%;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    @media (max-width: 768px) {
      margin-right: 0px;
      width: 100%;
    }
  `,
  ModalClose: styled.div`
    background: url(${closeIcon});
    height: 14px;
    width: 14px;
    margin-top: 23px;
    margin-right: 33px;

    &:hover {
      cursor: pointer;
    }
    margin-bottom: 18px;
  `,
  ModalHeader: styled.div`
    display: flex;
    justify-content: flex-end;
    font-size: 25px;
  `,
  ModalContent: styled.div`
    width: 100%;
  `
}
