import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import CustomButton from '../components/CustomButton/CustomButton';
import CustomInput from '../components/CustomInput/CustomInput';
import DropDown from '../components/DropDown/DropDown';
import Loader from '../components/Loader/Loader';
import Table from '../components/Table/Table';
import { TableText } from '../components/Table/Table.style';
import { capitalize, objToQueryString } from '../helpers';
import Layout from '../Layout/Layout';
import { Style } from './applicants.style';
import useFetch from './useFetch';
import pluralize from 'pluralize';
import debounce from 'lodash.debounce';
// import NaijaStates from 'naija-state-local-government';
import { useHistory } from 'react-router';
import CustomDatePicker from '../components/DatePicker/DatePicker';
import Process from '../components/Process';
import Modal from '../components/Modal/modal.component';
import AuthContext from '../context/authContext';
import { ToastContainer } from 'react-toastify';
import ChangePassword from '../components/ChangePassword';
import NaijaStates from '../states.json';
// import UpdateTrainingCenter from "../components/UpdateTrainingCenter";

console.log('NaijaStates', NaijaStates);
const programsList = [
  'All',
  'N-Teach',
  'N-Health',
  'N-Tax',
  'N-Agro',
  'N-Tech Hardware',
  'N-Tech Software',
  'N-Build',
  'N-Tech',
  'N-Creative',
  'N-Knowledge',
  'GRADUATE',
  'NON GRADUATE',
];

const educationList = [
  'All',
  'Ordinary Diploma',
  'Higher Diploma',
  'Bachelor\'s degree',
  'Post graduate degree',
  'Masters',
  'PHD (Doctorate)',
  'Secondary School',
  'Primary School',
  'No School'
];

const Applicants = () => {
  const columns = React.useMemo(
    () => [
      {
        Header: 'First name',
        accessor: 'first_name',
        Cell: ({ cell: { value } }) => {
          return <TableText>{capitalize(value)}</TableText>;
        },
      },
      {
        Header: 'Last name',
        accessor: 'last_name',
        Cell: ({ cell: { value } }) => {
          return <TableText>{value}</TableText>;
        },
      },
      {
        Header: 'Nasims ID',
        accessor: 'application_id',
        Cell: ({ cell: { value } }) => {
          // const parts = value ? value.split('-') : ''
          return <TableText>{value}</TableText>;
        },
      },
      {
        Header: 'Program',
        accessor: 'programme',
        Cell: ({ cell: { value } }) => {
          return <TableText>{value}</TableText>;
        },
      },
      {
        Header: 'State of Residence',
        accessor: 'state_of_residence',
        Cell: ({ cell: { value } }) => {
          return <TableText>{value}</TableText>;
        },
      },
      {
        Header: 'Email',
        accessor: 'email',
        Cell: ({ cell: { value } }) => {
          return <TableText>{value}</TableText>;
        },
      },
      {
        Header: 'Phone Number',
        accessor: 'phone_number',
        Cell: ({ cell: { value } }) => {
          return <TableText>{value}</TableText>;
        },
      },
    ],
    [],
  );

  const [filterParams, setFilterParams] = useState({});
  const [pageIndex, setPageIndex] = useState(0);
  const states = ['All', ...NaijaStates.map((obj) => obj.state)];
  // const states = NaijaStates.states();
  const statesOfResidence = states;
  const [lga, setLga] = useState(['All']);
  const [process, setProcess] = useState(false);
  const [passwordChange, setPasswordChange] = useState(false);
  const [lgaOfResidence, setLgaOfResidence] = useState(['All']);
  const benStatus = ['...', 'Yes', 'No'];
  const verificationStatus = ['...', 'Yes', 'No'];
  // states.unshift('All');
  // statesOfResidence.unshift('All');
  const history = useHistory();
  const filterRef = useRef();
  const [reset, setReset] = useState(false);
  const { getCurrentUser } = useContext(AuthContext);
  const user = getCurrentUser();
  if (!user) {
    history.push('/login');
  }

  const handleStateChange = (name, value) => {
    console.log('value', value);
    if (value === 'All') {
      setFilterParams((prevParams) => {
        delete prevParams[name];
        return { ...prevParams };
      });
      setLga(['All']);
      return;
    }
    const lgResult = NaijaStates.find(
      (data) => data.state === value,
    ).lgas.sort();
    lgResult.unshift('All');
    setLga(lgResult);
    setFilterParams((prevParams) => {
      delete prevParams['lga'];
      return { ...prevParams, [name]: value };
    });
    setPageIndex(0);
  };
  const handleStateResidenceChange = (name, value) => {
    if (value === 'All') {
      setFilterParams((prevParams) => {
        delete prevParams[name];
        return { ...prevParams };
      });
      setLgaOfResidence(['All']);
      return;
    }
    const lgResult = NaijaStates.find(
      (data) => data.state === value,
    ).lgas.sort();
    lgResult.unshift('All');
    setLgaOfResidence(lgResult);
    setFilterParams((prevParams) => {
      delete prevParams['local_government_of_residence'];
      return { ...prevParams, [name]: value };
    });
    setPageIndex(0);
  };

  const handleProgrammeChange = (name, value) => {
    if (value === 'All') {
      setFilterParams((prevParams) => {
        delete prevParams[name];
        return { ...prevParams };
      });
      setLga(['All']);
      return;
    }

    setFilterParams((prevParams) => {
      return { ...prevParams, [name]: `${value} - NPR` };
    });
    setPageIndex(0);
  };
  const handleChange = (name, value) => {
    if (value === 'All') {
      setFilterParams((prevParams) => {
        delete prevParams[name];
        return { ...prevParams };
      });
      return;
    }

    setFilterParams((prevParams) => {
      return { ...prevParams, [name]: value };
    });
    setPageIndex(0);
  };

  const handleStatusChange = (name, value) => {
    if (value === '...') {
      setFilterParams((prevParams) => {
        delete prevParams[name];
        return { ...prevParams };
      });
      return;
    }

    let val;
    if (value === 'Yes') {
      val = 1;
    } else {
      val = 0;
    }
    setFilterParams((prevParams) => {
      return { ...prevParams, [name]: val };
    });
    setPageIndex(0);
  };
  const queryString = objToQueryString(filterParams);

  const { data, isLoading, error, refetch, isFetching } = useFetch(
    queryString,
    pageIndex,
  );
  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [filterParams, pageIndex]);
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line
  }, []);

  // eslint-disable-next-line
  const fetchPaginatedData = useCallback((id) => {
    setPageIndex(id);
    window.scrollTo(0, 400);
  });
  const applicantCountText =
    data && data.rows
      ? data.rows.toLocaleString('en-US') + pluralize(' Applicant', data.rows)
      : pluralize('0 Applicant', 0);

  const InputfilterHandler = debounce(({ target }) => {
    const { value, name } = target;
    if ((value && value === '') || !value) {
      setFilterParams((prevParams) => {
        delete prevParams[name];
        return { ...prevParams };
      });

      setPageIndex(0);
      return;
    }
    setFilterParams((prevParams) => {
      return { ...prevParams, [name]: value };
    });
    setPageIndex(0);
  }, 1000);

  const handleDate = (name, value) => {
    if ((value && value === '01-01-1970') || !value) {
      setFilterParams((prevParams) => {
        delete prevParams[name];
        return { ...prevParams };
      });

      setPageIndex(0);
      return;
    }

    setFilterParams((prevParams) => {
      return { ...prevParams, [name]: value };
    });
    setPageIndex(0);
  };

  const rowHandler = (row) => {
    history.push(`/applicant?name=${row.name}`);
  };

  const clearFilter = () => {
    if (Object.keys(filterParams).length > 0) {
      setFilterParams({});
    }
    filterRef.current.reset();
    setLga(['All']);
    setReset(true);
    console.log(filterRef);
  };

  console.log(queryString);
  console.log(user);
  console.log('states', states);
  return (
    <Layout>
      <Style.Container>
        <Style.FilterWrapper
          ref={filterRef}
          onSubmit={(e) => e.preventDefault()}
        >
          <div className='heading-group'>
            <h1 className='heading'>Find Applicants</h1>
            <CustomButton
              text='Clear Search'
              noshadow
              border='3px'
              width='190px'
              nomargin
              style={{ marginBottom: '0px' }}
              handleButtonClick={clearFilter}
            />
          </div>
          <div className='filter-group' style={{ marginTop: '0px' }}>
            <div>
              <h2>First Name</h2>
              <CustomInput
                placeholder={'Search by first name'}
                type='text'
                height='40px'
                color='#fff'
                radius='2px'
                handleChange={InputfilterHandler}
                nomargin
                width='282px'
                name='first_name'
              />
            </div>
            <div>
              <h2>Last Name</h2>
              <CustomInput
                placeholder={'Search by last name'}
                type='text'
                height='40px'
                color='#fff'
                radius='2px'
                handleChange={InputfilterHandler}
                nomargin
                width='282px'
                name='surname'
              />
            </div>
            <div>
              <h2>Email</h2>
              <CustomInput
                placeholder={'Search email address'}
                type='text'
                height='40px'
                color='#fff'
                radius='2px'
                handleChange={InputfilterHandler}
                nomargin
                width='282px'
                name='email_address'
              />
            </div>

            <div>
              <h2>BVN</h2>
              <CustomInput
                placeholder={'Search BVN'}
                type='text'
                height='40px'
                color='#fff'
                radius='2px'
                handleChange={InputfilterHandler}
                nomargin
                width='282px'
                name='bvn'
              />
            </div>
          </div>
          <div className='filter-group'>
            <DropDown
              options={states}
              label={'State'}
              width='181px'
              handleChange={handleStateChange}
              name='state_of_origin'
              reset={reset}
              setReset={setReset}
            />
            <DropDown
              options={lga}
              label={'LGA'}
              width='181px'
              handleChange={handleChange}
              name='lga'
              reset={reset}
              setReset={setReset}
            />
            <DropDown
              options={programsList}
              label={'Programme'}
              width='181px'
              handleChange={handleProgrammeChange}
              name='programme'
              reset={reset}
              setReset={setReset}
            />
            <CustomDatePicker
              width='282px'
              reset={reset}
              setReset={setReset}
              label='Date of Birth'
              handleDate={handleDate}
              name='date_of_birth'
            />
            <div>
              <h2>Nasims ID</h2>
              <CustomInput
                placeholder={'Search Nasims ID'}
                type='text'
                height='40px'
                color='#fff'
                radius='2px'
                handleChange={InputfilterHandler}
                nomargin
                width='282px'
                name='application_id'
              />
            </div>
          </div>
          <div className='filter-group'>
            <DropDown
              options={benStatus}
              label={'Beneficiary Status'}
              width='282px'
              handleChange={handleStatusChange}
              name='beneficiary_status'
              reset={reset}
              setReset={setReset}
            />
            <DropDown
              options={verificationStatus}
              label={'Physical Verification Done'}
              width='282px'
              handleChange={handleStatusChange}
              name='physical_verification_done'
              reset={reset}
              setReset={setReset}
            />
            <DropDown
              options={statesOfResidence}
              label={'State of residence'}
              width='282px'
              handleChange={handleStateResidenceChange}
              name='state_of_residence'
              reset={reset}
              setReset={setReset}
            />
            <DropDown
              options={lgaOfResidence}
              label={'LGA of residence'}
              width='282px'
              handleChange={handleChange}
              name='local_government_of_residence'
              reset={reset}
              setReset={setReset}
            />
          </div>
          <div className='filter-group'>
            <div>
              <h2>Phone Number</h2>
              <CustomInput
                placeholder={'Search by phone number'}
                type='number'
                height='40px'
                color='#fff'
                radius='2px'
                handleChange={InputfilterHandler}
                nomargin
                width='282px'
                name='phone_number'
              />
            </div>
            <DropDown
              options={educationList}
              label={'Highest Level of Education'}
              width='282px'
              handleChange={handleChange}
              name='highest_level_of_education'
              reset={reset}
              setReset={setReset}
            />
          </div>
          {user && user.data?.role === 'super-admin' && (
            <div style={{ display: 'flex', alignItems: 'center', gap: '15px' }}>
              <CustomButton
                text={`Applicant's Process Actions`}
                noshadow
                border='3px'
                width='226px'
                nomargin
                handleButtonClick={() => setProcess(true)}
                disabled={isLoading || error}
                style={{
                  marginTop: '30px',
                  marginBottom: '30px',
                  textAlign: 'left',
                  paddingLeft: '18px',
                }}
              />
              <CustomButton
                text={`Change Agent Password`}
                noshadow
                border='3px'
                width='226px'
                nomargin
                handleButtonClick={() => setPasswordChange(true)}
                disabled={isLoading || error}
                style={{
                  marginTop: '30px',
                  marginBottom: '30px',
                  textAlign: 'left',
                  paddingLeft: '18px',
                }}
              />
            </div>
          )}
          {isFetching ? <h2 className='fetching'>Fetching records...</h2> : ''}
        </Style.FilterWrapper>
        <Style.TableContainer>
          <h2 className='heading'>{applicantCountText}</h2>
          {isLoading || error ? (
            <Loader />
          ) : (
            <Table
              columns={columns}
              data={data ? data.result : []}
              fetchPaginatedData={fetchPaginatedData}
              pageCount={data.total_pages}
              initialState={pageIndex}
              rowHandler={rowHandler}
            />
          )}
        </Style.TableContainer>
      </Style.Container>
      <Modal
        show={process}
        onClose={() => {
          setProcess(false);
        }}
        width='466px'
      >
        <Process handleClose={() => setProcess(false)} />
      </Modal>
      <Modal
        show={passwordChange}
        onClose={() => {
          setPasswordChange(false);
        }}
        width='466px'
      >
        <ChangePassword handleClose={() => setPasswordChange(false)} />
      </Modal>
      {/* <Modal show={passwordChange} onClose={() => {setPasswordChange(false)}} width="466px">
        <UpdateTrainingCenter
            handleClose={() => setPasswordChange(false)}
        />
      </Modal> */}
      <ToastContainer
        position='bottom-center'
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </Layout>
  );
};

export default Applicants;
