import styled from 'styled-components'

export const CustomButtonStyle = styled.button`
  width: ${({ width }) => (width ? width : '100%')};
  height: ${({ height }) => (height ? height : '45px')};
  background: ${props => props.theme.primaryColor};
  cursor: pointer;
  &:disabled {
    background: #7ec78b87;
    cursor: not-allowed !important;
  }
  ${({ noshadow }) =>
    noshadow ? '' : 'box-shadow: 0px 10px 15px rgba(30, 38, 109, 0.1);'}
  border-radius: ${({ border }) => (border ? border : '5px')};
  outline: none;
  border: none;
  margin-bottom: ${({ nomargin }) => (nomargin ? '0px' : '16px')};
  color: ${props => props.theme.white};
  &:focus {
    outline: none;
  }
  font-family: Rubik;
  font-style: normal;
  font-weight: 600;
  font-size: ${({ size }) => (size ? size : '14px')};
  line-height: ${({ lineHeight }) => (lineHeight ? lineHeight : '18px')};

  .align-elem{
    display: flex;
    align-items: center;
    justify-content: ${({ notCentered }) =>
      notCentered ? 'flex-start' : 'center'};
  }
`
