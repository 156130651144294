import React, {useContext, useEffect, useMemo, useState} from "react";
import {ContainerStyle} from "./UpdateDetails/style";
import CustomInput from "./CustomInput/CustomInput";
import CustomButton from "./CustomButton/CustomButton";
import AuthContext from "../context/authContext";
import {useHistory} from "react-router-dom";
import {apiClient} from "../service";
import axios from "axios";
import {emitToast} from "../Register/Register";


const ChangePassword = ({handleClose}) => {
    const [form, setForm] = useState({
        email: '',
        password: '',
        confirmPassword: ''
    })
    const [formErrors, setFormErrors] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const history = useHistory()
    const { getCurrentUser } = useContext(AuthContext)
    const user = getCurrentUser()
    let source = axios.CancelToken.source()
    let config = { cancelToken: source.token}


    if (!user) {
        history.push('/login')
    }

    const validate = () => {
        let errors = {}
        const {email, password, confirmPassword} = form
        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i
        if (email.trim() === '') {
            errors.email = 'Email Cannot be blank'
        } else if (!regex.test(email)) {
            errors.email_address = 'Invalid email format'
        }
        if (password.trim() === '') {
            errors.password = 'Password Cannot be blank'
        } else if (password.trim() !== confirmPassword.trim()) {
            errors.confirmPassword = 'Password mismatch'
        }
        if (Object.keys(errors).length === 0) {
            return true
        }
        setFormErrors(errors)
        return false
    }

    const handleInputChange = ({ target }) => {
        setForm({
            ...form,
            [target.name]: target.value
        })
    }

    const handleSubmit = async (e) => {
        e.preventDefault()
        setFormErrors({})
        setIsLoading(true)

        if(validate()){
            const data = new FormData()
            data.append('admin_id', user.data.name)
            data.append('agent_email', form.email.trim())
            data.append('new_password', form.password.trim())
            try {
                const response = await apiClient.put(
                    '/agent/password',
                    data,
                    config
                )
                setIsLoading(false)
                if (response.status === 200) {
                    emitToast('success', 'Agent password changed successfully')
                    handleClose()
                }
            } catch (error) {
                setIsLoading(false)
                setFormErrors({error: error.response?.data?.message})
                emitToast('danger', 'Error changing agent password')
                console.log(error)
            }
        } else {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        return () => {
            source.cancel("Unmounted")
        }
    }, []);

    return (
        <ContainerStyle>
            <h2 className='title'>Change Agent Password</h2>
            {Object.keys(formErrors).length > 0 &&
            Object.keys(formErrors).map((err, id) => (
                <p style={{ color: 'red' }} key={id}>
                    {formErrors[err]}
                </p>
            ))}
            <form onSubmit={handleSubmit}>
                <div className="row">
                    <div className="input__group">
                        <label htmlFor="email">Email</label>
                        <CustomInput
                            type='email'
                            height='45px'
                            color='#fff'
                            radius='5px'
                            nomargin
                            handleChange={handleInputChange}
                            name='email'
                            id="email"
                            value={form.email}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="input__group">
                        <label htmlFor="password">Password</label>
                        <CustomInput
                            type='password'
                            height='45px'
                            color='#fff'
                            radius='5px'
                            nomargin
                            handleChange={handleInputChange}
                            name='password'
                            id="password"
                            value={form.password}
                        />
                    </div>
                    <div className="input__group">
                        <label htmlFor="confirmPassword">Confirm Password</label>
                        <CustomInput
                            type='password'
                            height='45px'
                            color='#fff'
                            radius='5px'
                            nomargin
                            handleChange={handleInputChange}
                            name='confirmPassword'
                            id="confirmPassword"
                            value={form.confirmPassword}
                        />
                    </div>
                </div>
                <div className="button__container">
                    <CustomButton
                        text='Change Password'
                        noshadow
                        border='3px'
                        width='360px'
                        nomargin
                        style={{ marginBottom: '0px' }}
                        type='submit'
                        isLoading={isLoading}
                    />
                </div>
            </form>
        </ContainerStyle>
    )
}

export default ChangePassword
